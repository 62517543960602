import React from 'react';
import { animated, useSpringValue } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';

import { styled } from '@ui/styles';
import { useNodeLayout } from '@ui/hooks';
import { Box, BoxProps, Text } from '@ui/components';

import MobileContext from '@web/components/MobileContext';

const Root = styled(Box)(({ theme }) => ({
	position: 'relative',
	maxWidth: 904,
	paddingTop: theme.spacing(9),
	overflow: 'hidden',
}));

const Wrapper = styled(animated.div, {
	shouldForwardProp: prop => prop !== 'isDragging',
})<{ isDragging?: boolean }>(({ theme, isDragging }) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	overflowX: 'scroll',
	overflowY: 'hidden',
	// scrollSnapType: isDragging ? undefined : 'x mandatory',
	scrollBehavior: 'smooth',
	'& > div': {
		// scrollSnapAlign: isDragging ? undefined : 'start',
		scrollBehavior: 'smooth',
	},
	'::-webkit-scrollbar': {
		display: 'none',
	},
	transition: 'all 0.3s ease-in-out',
	scrollbarWidth: 'none',
	touchAction: 'none',
	paddingTop: theme.spacing(4),
}));

const Card = styled(animated.div)(({ theme }) => ({
	height: 100,
	minWidth: '40%',
	textAlign: 'center',
	flex: 'none',
	touchAction: 'none',
	// pointerEvents: 'none',
	userSelect: 'none',
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
}));

const SliderWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	height: 1,
	backgroundColor: '#C07B7B',
}));

const Slider = styled(animated.div)(({ theme }) => ({
	position: 'absolute',
	height: 2,
	backgroundColor: '#FFFFFF',
	top: -0.5,
	left: 0,
}));

const AssesText = styled(Text)(({ theme }) => ({
	fontFamily: theme.fontFamily.chalet,
	fontWeight: '700',
	fontSize: '1.25rem',
	lineHeight: '1.25rem',
	maxWidth: 280,
	textAlign: 'left',
	flex: 'none',
	touchAction: 'none',
	// pointerEvents: 'none',
	userSelect: 'none',
}));

const Indicator = styled(animated.div)(({ theme }) => ({
	fontFamily: theme.fontFamily.mulish,
	fontWeight: '400',
	fontSize: '0.875rem',
	textAlign: 'right',
	lineHeight: '1.5rem',
	color: theme.palette.primary.main,
}));

const KYSlider: React.FC<Omit<BoxProps, 'ref'>> = ({ px, ...props }) => {
	const isMobile = React.useContext(MobileContext);
	const dragRef = React.useRef<boolean>(false);
	const ref = React.useRef<HTMLDivElement>(null);
	const offsetRef = React.useRef<number>(0);
	const scrollLeft = useSpringValue(0);
	const { scrollWidth, width } = useNodeLayout(ref);
	const scrollW = scrollWidth - width;
	const gestureBinds = useGesture(
		{
			onDrag: ({ movement: [xDelta], first, last }) => {
				const d = offsetRef.current + -xDelta;
				if (first) {
					dragRef.current = true;
				}
				if (last) {
					if (d < 100) {
						offsetRef.current = 0;
					} else if (d > scrollW - 100) {
						offsetRef.current = scrollW;
					} else {
						offsetRef.current = d;
					}
					dragRef.current = false;
				}
				if (dragRef.current) {
					if (d < 100) {
						scrollLeft.set(0);
					} else if (d > scrollW - 100) {
						scrollLeft.set(scrollW);
					} else {
						scrollLeft.set(d);
					}
				}
			},
			onWheel: ({ offset: [xOffset], last }) => {
				if (!dragRef.current) {
					if (last) {
						if (xOffset < 100) {
							offsetRef.current = 0;
						} else if (xOffset > scrollW - 100) {
							offsetRef.current = scrollW;
						} else {
							offsetRef.current = xOffset;
						}
					}
					if (xOffset < 100) {
						scrollLeft.set(0);
					} else if (xOffset > scrollW - 100) {
						scrollLeft.set(scrollW);
					} else {
						scrollLeft.set(xOffset);
					}
				}
			},
		},
		{
			drag: {
				delay: 1000,
			},
		},
	);
	return (
		<>
			<Root {...props}>
				<Wrapper ref={ref} scrollLeft={scrollLeft} {...gestureBinds()}>
					<Card dir="ltr">
						<AssesText>
							“Gostei, não tem cheiro, não escorre, fácil de
							aplicar.”
						</AssesText>
					</Card>
					<Card dir="ltr">
						<AssesText>
							“Achei excelente, pois é um hidratante vaginal de
							fácil aplicação, sem odor e não escorre”
						</AssesText>
					</Card>
					<Card dir="ltr">
						<AssesText>
							“Sensação de hidratação (úmida na região), tirando o
							desconforto de secura.”
						</AssesText>
					</Card>
					<Card dir="ltr">
						<AssesText>
							“Produto não escorre, como outros que já usei.”
						</AssesText>
					</Card>
					<Card dir="ltr">
						<AssesText>
							“Não tive mais ressecamento vaginal.”
						</AssesText>
					</Card>
				</Wrapper>
				<Box px={px}>
					<SliderWrapper>
						<Slider
							style={{
								width: scrollLeft
									.to(
										[0, scrollW],
										[
											(width / 5) * (isMobile ? 1 : 2),
											width,
										],
									)
									.to(val => `${val}px`),
							}}
						/>
					</SliderWrapper>
					<Indicator>
						{scrollLeft
							.to([0, scrollW], [isMobile ? 1 : 2, 5])
							.to(val => `${Math.round(val)}/5`)}
					</Indicator>
				</Box>
			</Root>
		</>
	);
};
export default KYSlider;
