import React from 'react';
import { animated, useInView } from '@react-spring/web';

import { Box, BoxProps, Text } from '@ui/components';
import { styled } from '@ui/styles';

import MobileContext from '@web/components/MobileContext';

import Clinical from '@common-lib/modules/brands/components/ky-clinical/Clinical';
import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import ContentBox from '@common-lib/components/Content/ContentBox';

import TestedSeal from './TestedSeal';
import MoisturizerItem from './MoisturizerItem';

const Root = styled(ContentWrapper)(({ theme }) => ({
	overflowX: 'visible',
	overflowY: 'hidden',
	maxHeight: 1035,
	minHeight: 1035,
	[theme.breakpoints.down('sm')]: {
		maxHeight: 700,
		minHeight: 700,
	},
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
}));
const Wrapper = styled(ContentBox)(({ theme }) => ({
	background: 'transparent',
	maxHeight: 1035,
	minHeight: 1035,
	display: 'flex',
	position: 'relative',
	flexDirection: 'row',
	justifyContent: ' space-between',
	[theme.breakpoints.down('sm')]: {
		minHeight: '100%',
		paddingTop: 20,
		flexDirection: 'column-reverse',
	},
}));
const TextData = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-end',
	zIndex: 1,
	paddingBottom: theme.spacing(10),
	[theme.breakpoints.down('sm')]: {
		paddingBottom: theme.spacing(6),
	},
}));
const BG = styled(Box)(({ theme }) => ({
	background: "url('/images/ky_moisturizer_cover.png')",
	maxHeight: 1313,
	minHeight: 1313,
	minWidth: 1168,
	maxWidth: 1168,
	position: 'absolute',
	bottom: 0,
	left: -220,
	[theme.breakpoints.up('xl')]: {
		bottom: 0,
		left: -360,
	},
	[theme.breakpoints.down('sm')]: {
		minWidth: '100%',
		maxWidth: '100%',
		maxHeight: '100%',
		minHeight: '100%',
	},
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	// zIndex: 0,
}));

const Title = styled(Text)(({ theme }) => ({
	fontSize: '3rem',
	lineHeight: '3rem',
	fontFamily: theme.fontFamily.chalet,
	[theme.breakpoints.down('sm')]: {
		maxWidth: '100%',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
}));
const Desc = styled(Text)(({ theme }) => ({
	fontSize: '1rem',
	lineHeight: '1.5rem',
	fontFamily: theme.fontFamily.mulish,
	[theme.breakpoints.up('sm')]: {
		maxWidth: 348,
	},
	[theme.breakpoints.down('sm')]: {
		maxWidth: '100%',
	},
}));

const IntroText = styled(animated.div)(({ theme }) => ({
	fontSize: '3rem',
	lineHeight: '3rem',
	fontFamily: theme.fontFamily.chalet,
	'@supports not (-webkit-text-stroke: inherit)': {
		color: '#ffff',
	},
	[theme.breakpoints.down('sm')]: {
		maxWidth: '100%',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
}));
const BGColor = styled('svg')(({ theme }) => ({
	position: 'absolute',
	zIndex: 0,
	top: 150,
	left: -380,
	// [theme.breakpoints.down('sm')]: {
	// 	top: -120,
	// 	left: -380,
	// },
}));

const OulinedText: React.FC = () => {
	const [ref, prop] = useInView(() => ({
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0,
		},
		config: {
			duration: 1000,
		},
	}));
	return (
		<IntroText
			ref={ref}
			style={{
				WebkitTextFillColor: prop.opacity.to(
					v => `rgba(255, 255, 255, ${v})`,
				),
				WebkitTextStroke: prop.opacity
					.to([1, 0], [0, 1])
					.to(v => `1px rgba(255, 255, 255, ${v})`),
			}}
		>
			felicidade
			<br /> íntima
		</IntroText>
	);
};

const Moisturizer: React.FC<Omit<BoxProps, 'ref'>> = ({ px, ...props }) => {
	const isMobile = React.useContext(MobileContext);
	return (
		<>
			<ContentWrapper>
				<Root>
					{isMobile ? (
						<BGColor
							width="1155"
							height="1221"
							viewBox="0 0 1155 1221"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g filter="url(#filter0_f_427_2)">
								<path
									d="M394.868 960.685C367.426 874.899 654.953 779.095 638.737 685.13C752.971 659.492 867.822 708.251 895.264 794.037C922.707 879.823 852.348 970.15 738.115 995.788C623.881 1021.43 422.31 1046.47 394.868 960.685Z"
									fill="#AC6F7C"
									fillOpacity="0.2"
								/>
							</g>
							<g filter="url(#filter1_f_427_2)">
								<path
									d="M801.931 531.578C778.944 617.363 522.471 579.913 487.252 668.081C391.567 642.442 332.634 552.115 355.62 466.329C378.607 380.543 474.808 331.784 570.492 357.423C666.176 383.061 824.917 445.792 801.931 531.578Z"
									fill="#D7A2AC"
									fillOpacity="0.6"
								/>
							</g>
							<defs>
								<filter
									id="filter0_f_427_2"
									x="193.02"
									y="478.299"
									width="908.178"
									height="741.808"
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB"
								>
									<feFlood
										floodOpacity="0"
										result="BackgroundImageFix"
									/>
									<feBlend
										mode="normal"
										in="SourceGraphic"
										in2="BackgroundImageFix"
										result="shape"
									/>
									<feGaussianBlur
										stdDeviation="100"
										result="effect1_foregroundBlur_427_2"
									/>
								</filter>
								<filter
									id="filter1_f_427_2"
									x="0.65036"
									y="0.591309"
									width="1153.53"
									height="1017.49"
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB"
								>
									<feFlood
										floodOpacity="0"
										result="BackgroundImageFix"
									/>
									<feBlend
										mode="normal"
										in="SourceGraphic"
										in2="BackgroundImageFix"
										result="shape"
									/>
									<feGaussianBlur
										stdDeviation="175"
										result="effect1_foregroundBlur_427_2"
									/>
								</filter>
							</defs>
						</BGColor>
					) : (
						<BGColor
							width="1674"
							height="1423"
							viewBox="0 0 1674 1423"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g filter="url(#filter0_f_2003_930)">
								<path
									d="M205.127 1045.09C136.372 788.498 856.75 501.935 816.122 220.873C1102.33 144.185 1390.08 290.03 1458.83 546.627C1527.59 803.224 1351.31 1073.41 1065.11 1150.09C778.901 1226.78 273.882 1301.69 205.127 1045.09Z"
									fill="#AC6F7C"
									fillOpacity="0.2"
								/>
							</g>
							<defs>
								<filter
									id="filter0_f_2003_930"
									x="0.496735"
									y="0.439697"
									width="1673.2"
									height="1422.4"
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB"
								>
									<feFlood
										floodOpacity="0"
										result="BackgroundImageFix"
									/>
									<feBlend
										mode="normal"
										in="SourceGraphic"
										in2="BackgroundImageFix"
										result="shape"
									/>
									<feGaussianBlur
										stdDeviation="100"
										result="effect1_foregroundBlur_2003_930"
									/>
								</filter>
							</defs>
						</BGColor>
					)}
				</Root>
				<Wrapper {...props} px={isMobile ? px : undefined}>
					{!isMobile ? <BG /> : null}

					{isMobile ? null : (
						<TextData>
							<OulinedText />
						</TextData>
					)}
					<TextData>
						<Title color="primary">
							KY® Hidratante <br /> Intravaginal
						</Title>
						<Desc pt={4}>
							A solução KY® para auxiliar no tratamento e
							prevenção da secura vaginal.
							<br />
							<br />
							KY® Hidratante Intravaginal é um gel que promove
							hidratação prolongada ajudando a restaurar a umidade
							natural da região íntima da mulher.
						</Desc>
					</TextData>
					{!isMobile ? <TestedSeal /> : null}
					{!isMobile ? <Clinical /> : null}
				</Wrapper>
				<Box position="relative" minHeight={isMobile ? 300 : undefined}>
					<MoisturizerItem />
					{isMobile ? <TestedSeal /> : null}
					{isMobile ? <Clinical /> : null}
				</Box>
			</ContentWrapper>
		</>
	);
};

export default Moisturizer;
