import React from 'react';
import { animated, useInView } from '@react-spring/web';

import { styled } from '@ui/styles';
import { BoxProps, Box, Text, Image } from '@ui/components';
import { Col, Row, RowProps, useWidth } from '@ui/index';

import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import ContentBox from '@common-lib/components/Content/ContentBox';

const Root = styled(ContentWrapper)(({ theme }) => ({
	position: 'relative',
	background: 'linear-gradient(61.03deg, #FFFAFB 9.1%, #F4CBD3 100.49%)',
	[theme.breakpoints.down('sm')]: {
		background: '#FEE5E9',
	},
}));

const Wrapper = styled(ContentBox)(({ theme }) => ({
	position: 'relative',
	[theme.breakpoints.up('sm')]: {
		paddingBottom: theme.spacing(20),
		maxHeight: 1700,
	},
	display: 'flex',
	flexDirection: 'column',
}));

const CircleWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'flex',
	paddingTop: theme.spacing(2),
	flex: '1 1 auto',
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		minHeight: 1500,
	},
	[theme.breakpoints.down('sm')]: {
		minHeight: 700,
		paddingTop: theme.spacing(4),
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
	},
}));

const DescText = styled(Text)(({ theme }) => ({
	fontSize: '1rem',
	lineHeight: '1.5rem',
	paddingTop: theme.spacing(4),
	fontFamily: theme.fontFamily.mulish,
	[theme.breakpoints.up('sm')]: {
		maxWidth: 547,
	},
}));
const SubHead = styled(Text)(({ theme }) => ({
	fontSize: '1.5rem',
	lineHeight: '1.5rem',
	fontWeight: '700',
	fontFamily: theme.fontFamily.mulish,
	paddingTop: theme.spacing(4),
	[theme.breakpoints.up('sm')]: {
		paddingTop: theme.spacing(8),
	},
}));

const CircleInfo = styled(animated.div)(({ theme }) => ({
	position: 'absolute',
	width: 477,
	height: 477,
	border: `2px solid ${theme.palette.primary.main}`,
	borderRadius: '50%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	[theme.breakpoints.down('sm')]: {
		'&.circle-1': {
			width: 220,
			height: 220,
		},
		'&.circle-2': {
			width: 265,
			height: 265,
		},
		'&.circle-3': {
			width: 220,
			height: 220,
		},
	},
}));

const CircleBG = styled(Box)(({ theme }) => ({
	position: 'absolute',
	width: 564,
	height: 564,
	background: `linear-gradient(221.79deg, #FFBA7B 4.44%, rgba(254, 229, 233, 0) 93.97%)`,
	borderRadius: 400,
	transform: 'rotate(-180deg)',
	top: -43.5,
	right: -43.5,
	filter: 'blur(30px)',
	opacity: 0.3,
	[theme.breakpoints.down('sm')]: {
		'&.bg-1': {
			top: -15,
			right: -15,
			width: 272,
			height: 272,
		},
		'&.bg-2': {
			top: 38,
			right: 38,
			width: 260,
			height: 260,
		},
		'&.bg-3': {
			top: -15,
			right: -15,
			width: 272,
			height: 272,
		},
	},
	[theme.breakpoints.up('sm')]: {
		'&.bg-1': {
			top: -15,
			right: -15,
		},
		'&.bg-2': {
			top: 38,
			right: 38,
		},
		'&.bg-3': {
			top: -15,
			right: -15,
		},
	},
	transition: 'all 0.5s ease-in-out',
}));

const Title = styled(animated.div)(({ theme }) => ({
	fontSize: '7.5rem',
	lineHeight: '7.5rem',
	fontFamily: theme.fontFamily.chalet,
	color: theme.palette.primary.contrastText,
	[theme.breakpoints.down('sm')]: {
		maxWidth: '100%',
		fontSize: '4rem',
		lineHeight: '4rem',
	},
	'@supports not (-webkit-text-stroke: inherit)': {
		color: '#9A4856',
	},
}));

const CircleDesc = styled(Text)(({ theme }) => ({
	fontSize: '1rem',
	lineHeight: '1.5rem',
	fontFamily: theme.fontFamily.mulish,
	[theme.breakpoints.up('sm')]: {
		maxWidth: 358,
	},
	[theme.breakpoints.down('sm')]: {
		maxWidth: 200,
	},
}));

const CircleContent = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		'&.content-1': {
			paddingTop: theme.spacing(6),
		},
		'&.content-2': {
			paddingTop: theme.spacing(8),
		},
		'&.content-3': {
			paddingTop: theme.spacing(4),
		},
	},
	[theme.breakpoints.down('sm')]: {
		padding: 4,
	},
}));

const SlideImage = styled(Image)(({ theme }) => ({
	background: theme.palette.text.disabled,
	border: '10px solid #FFFFFF',
	[theme.breakpoints.down('sm')]: {
		border: '5px solid #FFFFFF',
	},
}));
const SlideWrapper = styled(Row)(({ theme }) => ({
	marginTop: -120,
	width: '100%',
	left: 0,
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	minHeight: 500,
	[theme.breakpoints.down('sm')]: {
		marginTop: 0,
		overflow: 'hidden',
	},
}));
const SlideItem = styled(Col)(({ theme }) => ({
	position: 'relative',
}));

const OulinedText: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [ref, prop] = useInView(() => ({
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0,
		},
		config: {
			duration: 1000,
		},
	}));
	return (
		<Title
			ref={ref}
			style={{
				WebkitTextFillColor: prop.opacity.to(
					v => `rgba(154, 72, 86, ${v})`,
				),
				WebkitTextStroke: prop.opacity
					.to([1, 0], [0, 1])
					.to(v => `1px rgba(154, 72, 86, ${v})`),
			}}
		>
			{children}
		</Title>
	);
};

const Circle1: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
	const [bgRef, inView] = useInView();
	const [ref, spring] = useInView(
		() =>
			isMobile
				? {
						from: {
							top: 0,
							right: -20,
						},
						to: {
							top: 20,
							right: 0,
						},
				  }
				: {
						from: {
							top: 0,
							right: 0,
						},
						to: {
							top: -40,
							right: 0,
						},
				  },
		{
			// once: true,
		},
	);
	return (
		<CircleInfo ref={ref} style={spring} className="circle-1">
			<CircleBG ref={bgRef} className={inView ? 'bg-1' : ''} />
			<CircleContent className="content-1">
				<OulinedText>94%</OulinedText>
				<CircleDesc>
					Consideraram uma melhoria global “muito boa” e “boa” da
					região íntima.
				</CircleDesc>
			</CircleContent>
		</CircleInfo>
	);
};
const Circle2: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
	const [bgRef, inView] = useInView();
	const [ref, spring] = useInView(
		() =>
			isMobile
				? {
						from: {
							top: '30%',
							left: -50,
						},
						to: {
							top: '30%',
							left: -20,
						},
				  }
				: {
						from: {
							top: '15%',
							left: 0,
						},
						to: {
							top: '10%',
							left: 40,
						},
				  },
		{
			// once: true,
		},
	);

	return (
		<CircleInfo ref={ref} style={spring} className="circle-2">
			<CircleBG ref={bgRef} className={inView ? 'bg-2' : ''} />
			<CircleContent className="content-2">
				<OulinedText>97%</OulinedText>
				<CircleDesc>
					Indicaram “muita melhoria” e “melhoria” na sensação de
					conforto da região íntima, após 28 dias de uso contínuo do
					produto.
				</CircleDesc>
			</CircleContent>
		</CircleInfo>
	);
};
const Circle3: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
	const [bgRef, inView] = useInView();
	const [ref, spring] = useInView(
		() =>
			isMobile
				? {
						from: {
							bottom: 0,
							right: -40,
							left: 'auto',
						},
						to: {
							left: 'auto',
							bottom: 60,
							right: -20,
						},
				  }
				: {
						from: {
							y: 0,
							bottom: 320,
							left: '44%',
						},
						to: {
							y: -150,
							bottom: 320,
							left: '44%',
						},
						immediate: false,
				  },
		{
			// once: true,
		},
	);

	return (
		<CircleInfo ref={ref} style={spring} className="circle-3">
			<CircleBG ref={bgRef} className={inView ? 'bg-3' : ''} />
			<CircleContent className="content-3">
				<OulinedText>97%</OulinedText>
				<CircleDesc>Gostaram muito da textura.</CircleDesc>
			</CircleContent>
		</CircleInfo>
	);
};

const Images: React.FC<Omit<RowProps, 'ref'>> = props => {
	const { xs, sm } = useWidth();
	const isMobile = xs || sm;
	const [bgRef, styles] = useInView(() => ({
		from: {
			x: 0,
		},
		to: {
			x: 1,
		},
	}));
	const dim = isMobile
		? {
				height: 180,
				width: 160,
		  }
		: {
				width: 240,
				height: 273,
		  };
	return (
		<SlideWrapper ref={bgRef} {...props}>
			<SlideItem xs={6} md={3}>
				<animated.div
					style={{
						y: styles.x.to([0, 1], [0, 20]),
						x: styles.x.to([0, 1], [0, isMobile ? -16 : 0]),
						transform: styles.x
							.to([0, 1], [0, -15.06])
							.to(value => `rotate(${value}deg)`),
					}}
				>
					<SlideImage
						src="/images/ky-result_slide_1.png"
						alt="slide 1"
						{...dim}
					/>
				</animated.div>
			</SlideItem>
			<SlideItem xs={6} md={3}>
				<animated.div
					style={{
						y: styles.x.to([0, 1], [0, -10]),
						transform: styles.x
							.to([0, 1], [0, 2.2])
							.to(value => `rotate(${value}deg)`),
					}}
				>
					<SlideImage
						src="/images/ky-result_slide_2.png"
						alt="slide 1"
						{...dim}
					/>
				</animated.div>
			</SlideItem>
			<SlideItem xs={6} md={3}>
				<animated.div
					style={{
						y: styles.x.to([0, 1], [0, isMobile ? -32 : -20]),
						x: styles.x.to([0, 1], [0, isMobile ? 80 : 0]),
						transform: styles.x
							.to([0, 1], [0, 16.78])
							.to(value => `rotate(${value}deg)`),
					}}
				>
					<SlideImage
						src="/images/ky-result_slide_3.png"
						alt="slide 1"
						{...dim}
					/>
				</animated.div>
			</SlideItem>
			<SlideItem xs={6} md={3}>
				<animated.div
					style={{
						y: styles.x.to([0, 1], [0, isMobile ? 20 : 60]),
						x: styles.x.to([0, 1], [0, isMobile ? 64 : 0]),
						transform: styles.x
							.to([0, 1], [0, 3.47])
							.to(value => `rotate(${value}deg)`),
					}}
				>
					<SlideImage
						src="/images/ky-result_slide_4.png"
						alt="slide 1"
						{...dim}
					/>
				</animated.div>
			</SlideItem>
		</SlideWrapper>
	);
};
const Evaluation: React.FC<Omit<BoxProps, 'ref'>> = ({ px, ...rest }) => {
	const { xs, sm } = useWidth();
	const isMobile = xs || sm;
	return (
		<Root>
			<Images />
			<Wrapper {...rest}>
				<Box pt={10} px={isMobile ? px : undefined}>
					<SubHead color="primary" pt={9}>
						Resultados de avaliação do produto:
					</SubHead>
					<DescText color="primary">
						* teste de uso realizado em voluntárias com
						acompanhamento médico por 28 dias.
					</DescText>
				</Box>
				<CircleWrapper>
					<Circle1 isMobile={isMobile} />
					<Circle2 isMobile={isMobile} />
					<Circle3 isMobile={isMobile} />
				</CircleWrapper>
			</Wrapper>
		</Root>
	);
};

export default Evaluation;
