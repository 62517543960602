import React from 'react';
import { AnimatedProps, animated, useInView } from '@react-spring/web';

import { styled } from '@ui/styles';
import { IconProps } from '@ui/icons/props';

import MobileContext from '@web/components/MobileContext';

const ItemBG = styled(animated.div)(({ theme }) => ({
	position: 'absolute',
	maxHeight: 584,
	minHeight: 584,
	minWidth: 412,
	maxWidth: 412,
	background: "url('/images/ky_clinical_item.png')",
	[theme.breakpoints.down('sm')]: {
		left: 0,
		right: 'auto',
		top: 'auto',
		bottom: -64,
		maxHeight: 418,
		minHeight: 418,
		minWidth: 248,
		maxWidth: 248,
	},
	[theme.breakpoints.up('sm')]: {
		left: 'auto',
		top: -490,
		right: '40%',
	},
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
}));
const MoisturizerItem: React.FC<
	AnimatedProps<
		Omit<
			React.DetailedHTMLProps<
				React.HTMLAttributes<HTMLDivElement>,
				HTMLDivElement
			>,
			'ref'
		>
	>
> = props => {
	const isMobile = React.useContext(MobileContext);
	const [ref, springs] = useInView(() =>
		isMobile
			? {
					from: {
						left: -40,
						bottom: -64,
					},
					to: {
						left: -20,
						bottom: -64,
					},
			  }
			: {
					from: {
						opacity: 0,
						x: 0,
					},
					to: {
						opacity: 1,
						x: 200,
					},
					delay: 1000,
			  },
	);

	return <ItemBG ref={ref} style={springs} {...props} />;
};

export default MoisturizerItem;
