import React from 'react';
import dynamic from 'next/dynamic';
import { Role } from '@interfaces';

import { styled } from '@ui/styles';
import { Box, BoxProps, Text } from '@ui/components';

import MobileContext from '@web/components/MobileContext';

import { BrandCode } from '@interfaces/brand';

import AuthGuard from '@common-lib/components/auth/AuthGuard';
import Background from '@common-lib/modules/brands/components/ky-clinical/Background';
import Intro from '@common-lib/modules/brands/components/ky-clinical/Intro';
import Moisturizer from '@common-lib/modules/brands/components/ky-clinical/Moisturizer';
import Info from '@common-lib/modules/brands/components/ky-clinical/Info';
import Indications from '@common-lib/modules/brands/components/ky-clinical/Indications';
import Evaluation from '@common-lib/modules/brands/components/ky-clinical/Evaluation';
import Assessments from '@common-lib/modules/brands/components/ky-clinical/Assessments';
import Formulation from '@common-lib/modules/brands/components/ky-clinical/Formulation';
import Difference from '@common-lib/modules/brands/components/ky-clinical/Difference';
import HashTag from '@common-lib/modules/brands/components/ky-clinical/HashTag';
import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import ContentBox from '@common-lib/components/Content/ContentBox';
// import ContentList from '@common-lib/modules/search/components/ContentList';
// import HomeButtons from '@common-lib/modules/landing/components/HomeButtons';
// import UpcomingEvents from '@common-lib/modules/event/components/UpcomingEvents';

const ContentList = dynamic(
	() => import('@common-lib/modules/search/components/ContentList'),
);
const HomeButtons = dynamic(
	() => import('@common-lib/modules/landing/components/HomeButtons'),
);
const UpcomingEvents = dynamic(
	() => import('@common-lib/modules/event/components/UpcomingEvents'),
);
const Wrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	height: '100%',
}));

const Banner = styled(Box)(({ theme }) => ({
	position: 'relative',
	[theme.breakpoints.up('sm')]: {
		paddingTop: theme.spacing(24),
		paddingBottom: theme.spacing(8),
		minHeight: theme.spacing(112),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		background: '#F4CBD3',
	},
	[theme.breakpoints.down('sm')]: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
		background:
			'linear-gradient(0deg, #F4CBD3 74.48%, rgba(244, 203, 211, 0) 100%)',
		marginTop: -200,
	},
	overflow: 'hidden',
}));

const PaddingBox = styled(Box)(({ theme }) => ({
	background: '#F7D2D6',
	paddingTop: 130,
	[theme.breakpoints.up('sm')]: {
		display: 'none',
	},
}));
const BG = styled(Box)(({ theme }) => ({
	background: "url('/images/ky-clinical_banner.png')",
	maxHeight: 2133,
	minHeight: 2133,
	minWidth: 1949,
	maxWidth: 1949,
	position: 'absolute',
	top: -580,
	left: -848,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	zIndex: 0,
}));
const BannerMobile = styled(Box)(({ theme }) => ({
	marginTop: theme.spacing(6),
	marginBottom: theme.spacing(8),
	height: 500,
	backgroundSize: 'cover',
	backgroundImage: "url('/images/ky-clinical_banner_mobile.png')",
}));

const InfoWrap = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
}));

const Title = styled(Text, { shouldForwardProp: prop => prop !== 'small' })<{
	small?: boolean;
}>(({ theme, small }) => ({
	fontSize: '1.25rem',
	letterSpacing: '1rem',
	paddingBottom: theme.spacing(small ? 2 : 4.5),
	textTransform: 'uppercase',
	color: theme.palette.primary.contrastText,
	fontWeight: '700',
}));

const Content = styled(ContentBox)(({ theme }) => ({
	paddingTop: theme.spacing(11),
	[theme.breakpoints.down('sm')]: {
		paddingTop: theme.spacing(5),
	},
	paddingBottom: theme.spacing(4),
}));

const BGColor = styled('svg')(({ theme }) => ({
	position: 'absolute',
	top: -220,
	// left: -380,
	zIndex: 0,
}));

const Landing: React.FC<
	Omit<BoxProps, 'ref' | 'children'> & {
		noRequestBtn?: boolean;
		noContent?: boolean;
	}
> = ({ noRequestBtn, noContent, ...props }) => {
	const isMobile = React.useContext(MobileContext);
	const px = isMobile ? 2 : 6;
	return (
		<ContentWrapper>
			<Wrapper {...props} fullWidth>
				{noContent ? (
					<PaddingBox pt={12} />
				) : (
					<>
						{isMobile ? <BannerMobile /> : null}
						<Banner px={px}>
							{isMobile ? null : <Background />}
							<ContentBox>
								{isMobile ? null : <BG />}
								<InfoWrap>
									<Box maxWidth={560}>
										<Title small={isMobile}>CLINICAL</Title>
										<Text
											variant="h1"
											maxWidth={848}
											color="primary"
										>
											A nova linha de tratamento íntimo da
											KY para sua paciente
										</Text>
										{noRequestBtn ? null : (
											<Box maxWidth={560}>
												<HomeButtons
													color="primary"
													small
												/>
											</Box>
										)}
									</Box>
								</InfoWrap>
								{noContent ? null : (
									<Box>
										<UpcomingEvents
											pt={2.5}
											withBg
											brand={BrandCode.KYCLINICAL}
											color="primary"
											hideLoader
										/>
									</Box>
								)}
							</ContentBox>
						</Banner>
						<HashTag />

						<ContentWrapper px={px}>
							<BGColor
								width="2476"
								height="1815"
								viewBox="0 0 2476 1815"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g filter="url(#filter0_f_1926_962)">
									<path
										d="M1520.96 466.649C1641.62 703.314 996.675 1133.55 1094.92 1400C830.947 1534.58 519.139 1451.83 398.477 1215.17C277.816 978.503 393.992 677.546 657.964 542.962C921.936 408.377 1400.3 229.984 1520.96 466.649Z"
										fill="#D7A2AC"
										fillOpacity="0.2"
									/>
								</g>
								<g filter="url(#filter1_f_1926_962)">
									<path
										d="M2236 741C2236 967.989 2030.72 1152 1777.5 1152C1524.28 1152 1319 967.989 1319 741C1741.75 910.235 1524.28 330 1777.5 330C2030.72 330 2236 514.011 2236 741Z"
										fill="#FFEFB7"
										fillOpacity="0.18"
									/>
								</g>
								<defs>
									<filter
										id="filter0_f_1926_962"
										x="0.783203"
										y="0.994873"
										width="1885.28"
										height="1813.53"
										filterUnits="userSpaceOnUse"
										colorInterpolationFilters="sRGB"
									>
										<feFlood
											floodOpacity="0"
											result="BackgroundImageFix"
										/>
										<feBlend
											mode="normal"
											in="SourceGraphic"
											in2="BackgroundImageFix"
											result="shape"
										/>
										<feGaussianBlur
											stdDeviation="175"
											result="effect1_foregroundBlur_1926_962"
										/>
									</filter>
									<filter
										id="filter1_f_1926_962"
										x="1079"
										y="90"
										width="1397"
										height="1302"
										filterUnits="userSpaceOnUse"
										colorInterpolationFilters="sRGB"
									>
										<feFlood
											floodOpacity="0"
											result="BackgroundImageFix"
										/>
										<feBlend
											mode="normal"
											in="SourceGraphic"
											in2="BackgroundImageFix"
											result="shape"
										/>
										<feGaussianBlur
											stdDeviation="120"
											result="effect1_foregroundBlur_1926_962"
										/>
									</filter>
								</defs>
							</BGColor>
							<AuthGuard
								requireRoles={[
									Role.ADMIN,
									Role.DOCTOR,
									Role.EVENT_AGENT,
									Role.EVENT_MANAGER,
								]}
							>
								<Content>
									<Text
										variant="h1"
										pb={3.5}
										component="div"
										color="primary"
									>
										Conteúdos K-Y clinical
									</Text>

									<ContentList
										brandCode={BrandCode.KYCLINICAL}
									/>
								</Content>
							</AuthGuard>
						</ContentWrapper>
					</>
				)}
				<Intro mt={noContent ? 0 : 10.5} px={px} pt={2} />
				<Moisturizer px={px} pt={2} />
				<Info px={px} pt={7} pb={6} />
				<Indications px={px} pb={6} />
				<Assessments px={px} pb={6} />
				<Evaluation px={px} />
				<Formulation px={px} />
				<Difference px={px} />
			</Wrapper>
		</ContentWrapper>
	);
};

export default Landing;
