export * from './components';
export * from './hooks';
export * from './icons';
export * from './styles';

export type Color =
	| 'primary'
	| 'secondary'
	| 'error'
	| 'warning'
	| 'info'
	| 'success';
