import useMediaQuery from '@mui/material/useMediaQuery';
// import { isWidthDown, isWidthUp } from '@mui/material/withWidth';
import { Theme } from '@mui/material/styles';

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
type UseWidth = {
	xs: boolean;
	sm: boolean;
	md: boolean;
	lg: boolean;
	xl: boolean;
	[key: string]: boolean;
};
type WidthLinked<T = any> = {
	[key in keyof UseWidth]: T;
};

const useWidth = (): UseWidth => {
	const xs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
	const sm = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'));
	const md = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'));
	const lg = useMediaQuery((theme: Theme) => theme.breakpoints.only('lg'));
	const xl = useMediaQuery((theme: Theme) => theme.breakpoints.only('xl'));

	return {
		xs,
		sm,
		md,
		lg,
		xl,
	};
};

const useWidthBasedValue = <T = any>(data: WidthLinked<T>) => {
	const unit = useWidth();
	const key = Object.keys(unit).find(m => unit[m]);
	if (key) {
		return data[key];
	}
	return data.xs;
};

// TODO: Material UI 5 discontinued withWidth
// export { useWidth as default, isWidthDown, isWidthUp };
export { useWidth as default, useWidthBasedValue };
export type { UseWidth, WidthLinked };
