import React from 'react';
import { AnimatedProps, animated, useInView } from '@react-spring/web';

import { useTheme } from '@ui/styles/hooks';
import { styled } from '@ui/styles';
import { IconProps } from '@ui/icons/props';

const SVG = styled(animated.svg)(({ theme }) => ({
	position: 'absolute',
	[theme.breakpoints.down('sm')]: {
		left: -20,
		top: -340,
		width: 222,
		height: 222,
	},
	[theme.breakpoints.up('sm')]: {
		left: -30,
		bottom: theme.spacing(-18),
	},
}));
const ApprovedSeal: React.FC<
	AnimatedProps<Omit<React.SVGProps<SVGSVGElement>, 'ref'>> &
		Pick<IconProps, 'color' | 'htmlColor'>
> = ({ color, htmlColor, ...rest }) => {
	const [ref, springs] = useInView(() => ({
		from: {
			opacity: 0,
			bottom: -180,
			scale: 1.5,
		},
		to: {
			opacity: 1,
			bottom: -144,
			scale: 1,
		},
		delay: 1000,
	}));
	const palette: any = useTheme().palette;
	const themeColor =
		htmlColor || (color && palette[color]?.main) || '#89C4CA';
	return (
		<SVG
			width="427"
			height="427"
			viewBox="0 0 427 427"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
			ref={ref}
			style={springs}
		>
			<g opacity="0.3" filter="url(#filter0_f_1724_841)">
				<rect
					x="425.932"
					y="252.796"
					width="305.573"
					height="305.573"
					rx="152.786"
					transform="rotate(145.483 425.932 252.796)"
					fill="url(#paint0_linear_1724_841)"
				/>
			</g>
			<g clipPath="url(#clip0_1724_841)">
				<path
					d="M158.433 241.636C160.375 247.169 157.229 253.107 151.283 255.194C145.338 257.28 139.172 254.606 137.233 249.07C135.622 244.471 137.038 240.161 140.752 237.445L142.222 241.634C140.698 243.188 139.954 245.485 140.777 247.829C141.947 251.165 146.092 252.676 149.928 251.33C153.792 249.975 156.092 246.209 154.919 242.87C154.107 240.559 152.025 239.214 149.861 238.954L148.405 234.795C152.985 234.636 156.849 237.128 158.433 241.636Z"
					fill={themeColor}
				/>
				<path
					d="M155.045 226.78L155.459 230.855L132.494 233.198L132.08 229.123L155.042 226.777L155.045 226.78Z"
					fill={themeColor}
				/>
				<path
					d="M155.016 219.46L154.919 223.552L138.945 223.17L139.042 219.078L155.016 219.46ZM137.303 219.038L137.205 223.13L133.391 223.039L133.489 218.947L137.303 219.038Z"
					fill={themeColor}
				/>
				<path
					d="M158.08 202.714L157.153 206.702L148.057 204.592C147.151 204.382 144.911 204.152 144.301 206.779C143.888 208.565 144.931 210.112 146.683 210.517L155.778 212.627L154.854 216.618L139.29 213.009L140.172 209.201L141.684 209.553C140.735 208.283 140.324 206.754 140.711 205.092C141.558 201.435 144.87 199.653 148.982 200.607L158.077 202.717L158.08 202.714Z"
					fill={themeColor}
				/>
				<path
					d="M161.166 196.631L159.446 200.35L144.946 193.641L146.666 189.922L161.166 196.631ZM145.087 189.194L143.367 192.913L139.904 191.31L141.624 187.59L145.087 189.194Z"
					fill={themeColor}
				/>
				<path
					d="M166.811 188.311C163.95 192.178 158.907 192.732 155.19 189.981C151.472 187.23 150.478 182.212 153.339 178.344C155.629 175.252 159.109 174.2 162.558 175.59L159.954 179.107C158.746 178.91 157.243 179.381 156.356 180.576C155.009 182.395 155.615 185.122 157.685 186.656C159.705 188.15 162.465 187.917 163.813 186.097C164.717 184.878 164.743 183.273 164.161 182.186L166.765 178.669C169.131 181.577 169.118 185.194 166.808 188.314L166.811 188.311Z"
					fill={themeColor}
				/>
				<path
					d="M184.39 171.714L181.267 174.063L180.243 172.698C180.035 174.485 179.116 176.105 177.555 177.282C174.233 179.781 169.315 178.97 166.482 175.199C163.629 171.404 164.219 166.457 167.544 163.961C169.108 162.787 170.92 162.357 172.692 162.652L171.668 161.286L174.794 158.941L184.393 171.717L184.39 171.714ZM170.31 166.613C168.499 167.975 168.229 170.661 169.793 172.743C171.321 174.774 173.959 175.241 175.77 173.878C177.581 172.516 177.832 169.806 176.342 167.822C174.816 165.788 172.121 165.25 170.31 166.613Z"
					fill={themeColor}
				/>
				<path
					d="M209.456 163.554L205.508 164.647L203.023 155.645C202.776 154.747 201.881 152.677 199.31 153.388C197.546 153.874 196.683 155.528 197.159 157.265L199.644 166.267L195.666 167.367L193.181 158.365C192.934 157.467 192.069 155.39 189.468 156.108C187.734 156.587 186.872 158.241 187.347 159.978L189.832 168.98L185.854 170.08L181.602 154.676L185.403 153.628L185.816 155.124C186.451 153.694 187.6 152.603 189.274 152.139C191.189 151.611 193.028 151.973 194.481 153.05C195.216 151.4 196.6 150.116 198.575 149.568C202.702 148.429 205.858 150.519 206.972 154.558L209.456 163.56L209.456 163.554Z"
					fill={themeColor}
				/>
				<path
					d="M215.242 158.245C215.583 160.239 217.016 161.591 218.93 161.771C220.291 161.899 221.672 161.438 222.474 160.234L226.829 160.643C225.599 163.8 222.581 165.728 218.627 165.357C214.146 164.937 210.821 160.884 211.255 156.281C211.689 151.677 215.718 148.253 220.196 148.676C224.891 149.119 228.44 153.128 227.259 159.376L215.242 158.245ZM223.525 155.659C223.205 153.761 221.77 152.437 219.825 152.259C217.972 152.083 216.378 153.118 215.618 154.915L223.525 155.659Z"
					fill={themeColor}
				/>
				<path
					d="M241.309 171.481L237.502 169.969L240.952 161.289C241.295 160.424 241.859 158.243 239.35 157.248C237.647 156.57 235.961 157.37 235.297 159.045L231.847 167.725L228.04 166.212L233.939 151.362L237.573 152.807L236.999 154.247C238.398 153.502 239.968 153.324 241.557 153.954C245.045 155.34 246.31 158.883 244.753 162.802L241.303 171.482L241.309 171.481Z"
					fill={themeColor}
				/>
				<path
					d="M258.268 175.782C255.814 178.986 251.817 179.715 248.415 177.112C245.014 174.509 244.652 170.442 247.106 167.238L255.235 156.62L258.486 159.112L256.015 162.341L263.801 168.3L261.464 171.354L253.678 165.395L250.226 169.902C249.338 171.06 249.149 172.788 250.776 174.033C252.378 175.26 254 174.623 254.882 173.465L255.805 172.257L259.056 174.749L258.265 175.785L258.268 175.782Z"
					fill={themeColor}
				/>
				<path
					d="M262.45 181.033C261.201 182.626 261.167 184.594 262.319 186.132C263.139 187.223 264.41 187.94 265.836 187.721L268.462 191.222C265.295 192.432 261.84 191.496 259.456 188.316C256.756 184.717 257.521 179.527 261.221 176.757C264.918 173.983 270.164 174.664 272.861 178.266C275.692 182.04 275.105 187.362 269.688 190.687L262.447 181.03L262.45 181.033ZM269.933 185.428C271.126 183.913 271.137 181.964 269.966 180.403C268.849 178.916 267.012 178.431 265.168 179.073L269.933 185.428Z"
					fill={themeColor}
				/>
				<path
					d="M277.123 235.689C273.103 235.345 270.338 232.371 270.701 228.102C271.067 223.837 274.298 221.341 278.319 221.685L291.644 222.824L291.296 226.906L287.248 226.56L286.414 236.329L282.579 236L283.413 226.231L277.757 225.75C276.304 225.625 274.745 226.396 274.569 228.433C274.396 230.442 275.805 231.465 277.257 231.59L278.771 231.719L278.423 235.801L277.123 235.689Z"
					fill={themeColor}
				/>
				<path
					d="M274.945 242.025C272.93 241.827 271.247 242.85 270.57 244.647C270.088 245.925 270.167 247.38 271.115 248.467L269.567 252.559C266.849 250.537 265.786 247.118 267.189 243.403C268.78 239.198 273.566 237.058 277.892 238.691C282.218 240.323 284.449 245.118 282.861 249.327C281.195 253.739 276.387 256.099 270.674 253.312L274.942 242.022L274.945 242.025ZM275.248 250.698C277.167 250.893 278.818 249.859 279.507 248.031C280.168 246.289 279.589 244.479 278.058 243.271L275.251 250.701L275.248 250.698Z"
					fill={themeColor}
				/>
				<path
					d="M259.62 257.265C262.029 253.989 265.539 252.834 268.606 254.476L266.142 257.824C264.712 257.466 263.338 258.494 262.621 259.469C261.647 260.794 261.312 262.086 262.059 262.637C262.784 263.168 263.414 262.943 265.421 260.948C267.465 258.907 270.347 256.292 273.223 258.409C275.246 259.9 276.043 263.218 273.299 266.941C271.129 269.892 267.91 270.911 265.175 269.552L267.695 266.126C268.441 266.369 269.449 265.99 270.351 264.768C271.473 263.246 271.381 262.215 270.906 261.865C270.231 261.37 269.309 262.154 267.88 263.567C265.002 266.38 262.61 268.162 259.809 266.103C257.262 264.228 257.187 260.554 259.617 257.256L259.62 257.265Z"
					fill={themeColor}
				/>
				<path
					d="M248.027 278.06C245.5 274.912 245.746 270.858 249.086 268.176C252.426 265.494 256.462 266.112 258.988 269.259L267.359 279.683L264.165 282.248L261.619 279.077L253.974 285.214L251.565 282.213L259.211 276.075L255.657 271.648C254.744 270.511 253.108 269.913 251.513 271.195C249.943 272.459 250.171 274.183 251.084 275.32L252.036 276.504L248.841 279.069L248.027 278.053L248.027 278.06Z"
					fill={themeColor}
				/>
				<path
					d="M228.17 279.639L231.785 278.152L232.433 279.731C233.087 278.054 234.38 276.715 236.188 275.974C240.034 274.391 244.587 276.412 246.383 280.77C248.188 285.161 246.374 289.801 242.531 291.381C240.72 292.125 238.862 292.087 237.219 291.352L237.866 292.931L234.251 294.418L228.17 279.639ZM240.516 288.116C242.609 287.253 243.549 284.722 242.556 282.313C241.587 279.961 239.15 278.851 237.054 279.711C234.961 280.573 234.035 283.135 234.98 285.43C235.949 287.781 238.423 288.979 240.516 288.116Z"
					fill={themeColor}
				/>
				<path
					d="M210.513 281.524L214.41 281.187L214.559 282.889C215.713 281.478 217.318 280.595 219.266 280.423C223.407 280.065 227.151 283.354 227.559 288.048C227.967 292.778 224.85 296.661 220.707 297.022C218.852 297.182 217.185 296.643 215.847 295.575L216.592 304.166L212.513 304.523L210.516 281.521L210.513 281.524ZM219.759 293.304C222.015 293.106 223.669 290.971 223.441 288.375C223.222 285.84 221.229 284.053 218.971 284.247C216.715 284.445 215.065 286.613 215.279 289.085C215.498 291.62 217.497 293.501 219.756 293.307L219.759 293.304Z"
					fill={themeColor}
				/>
				<path
					d="M191.666 286.007C192.643 281.328 196.926 278.577 201.268 279.487C205.609 280.396 208.407 284.623 207.429 289.302C206.452 293.981 202.186 296.795 197.842 295.889C193.497 294.983 190.688 290.686 191.666 286.007ZM195.711 286.823C195.147 289.528 196.484 291.77 198.612 292.214C200.739 292.657 202.862 291.138 203.427 288.433C203.979 285.793 202.631 283.606 200.504 283.162C198.376 282.718 196.266 284.179 195.714 286.82L195.711 286.823Z"
					fill={themeColor}
				/>
				<path
					d="M239.182 197.953C221.871 208.634 209.309 222.109 203.667 228.871L189.846 217.975L183.741 222.927L207.623 247.369C211.733 236.774 224.75 216.067 240.645 201.356L239.182 197.953Z"
					fill={themeColor}
				/>
			</g>
			<defs>
				<filter
					id="filter0_f_1724_841"
					x="0.665527"
					y="0.681641"
					width="425.601"
					height="425.601"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="30"
						result="effect1_foregroundBlur_1724_841"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_1724_841"
					x1="710.879"
					y1="260.053"
					x2="453.434"
					y2="548.056"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8EFF7B" />
					<stop offset="1" stopColor="#E5F5FE" stopOpacity="0" />
				</linearGradient>
				<clipPath id="clip0_1724_841">
					<rect
						width="154.738"
						height="149.679"
						fill="white"
						transform="translate(221.139 329.109) rotate(-136.919)"
					/>
				</clipPath>
			</defs>
		</SVG>
	);
};

export default ApprovedSeal;
