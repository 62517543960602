import React from 'react';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

type Dimensions = {
	top: number;
	left: number;
	width: number;
	height: number;
	scrollWidth: number;
	scrollHeight: number;
};

const useNodeLayout = (
	{ current }: React.RefObject<HTMLDivElement>,
	forceRefresh?: boolean,
	recalcTrigger?: any,
): Dimensions => {
	const [dimensions, setDimensions] = React.useState<Dimensions>({
		top: 0,
		left: 0,
		width: 0,
		height: 0,
		scrollWidth: 0,
		scrollHeight: 0,
	});
	const [mounted, setMounted] = React.useState<boolean | undefined>(
		undefined,
	);
	const handleSetDimensions = React.useCallback(() => {
		if (current) {
			setDimensions({
				top: current.offsetTop,
				left: current.offsetLeft,
				width: current.offsetWidth,
				height: current.offsetHeight,
				scrollWidth: current.scrollWidth,
				scrollHeight: current.scrollHeight,
			});
		}
	}, [current]);
	React.useEffect(() => {
		window.addEventListener('resize', handleSetDimensions);
		return () => {
			window.removeEventListener('resize', handleSetDimensions);
		};
	}, [handleSetDimensions]);
	useIsomorphicLayoutEffect(() => {
		if (current && (!mounted || forceRefresh || recalcTrigger)) {
			handleSetDimensions();
			setMounted(true);
		} else if (mounted === undefined) {
			setTimeout(() => setMounted(false), 1000);
		}
	}, [current, mounted, forceRefresh, handleSetDimensions, recalcTrigger]);
	return dimensions;
};

export default useNodeLayout;
