import React from 'react';

import { styled } from '@ui/styles';
import { BoxProps, Box, Row, Col, Text, Image } from '@ui/components';

import MobileContext from '@web/components/MobileContext';

import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import ContentBox from '@common-lib/components/Content/ContentBox';

const Wrapper = styled(ContentBox)(({ theme }) => ({
	position: 'relative',
}));

const InfoText = styled(Text)(({ theme }) => ({
	fontFamily: theme.fontFamily.chalet,
	fontWeight: 700,
	fontSize: '1.5rem',
	lineHeight: '1.5rem',
	[theme.breakpoints.up('sm')]: {
		maxWidth: 322,
	},
}));
const DescWrap = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	paddingBottom: theme.spacing(3),
	[theme.breakpoints.up('sm')]: {
		paddingTop: theme.spacing(5.5),
		paddingRight: theme.spacing(2),
	},
	[theme.breakpoints.down('sm')]: {
		paddingTop: theme.spacing(3),
	},
}));
const TheCol = styled(Col)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		borderLeft: '1px solid #D9D9D9',
		position: 'relative',
		':before': {
			top: 0,
			left: -10,
			content: '""',
			position: 'absolute',
			height: 100,
			width: 20,
			background: '#ffffff',
		},
	},
	[theme.breakpoints.down('sm')]: {
		borderTop: '1px solid #D9D9D9',
		position: 'relative',
		':before': {
			top: -5,
			left: 0,
			content: '""',
			position: 'absolute',
			height: 10,
			width: 16,
			background: '#ffffff',
		},
	},
}));
const TheRow = styled(Row)(({ theme }) => ({
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
	flexWrap: 'nowrap',
	[theme.breakpoints.up('sm')]: {
		borderBottom: '1px solid #D9D9D9',
		paddingBottom: theme.spacing(5.5),
		'&.left-row:before': {
			bottom: -1,
			right: '99%',
			content: '""',
			position: 'absolute',
			height: 1,
			width: '50vw',
			background: '#D9D9D9',
		},
		'&.right-row:before': {
			bottom: -1,
			left: '99%',
			content: '""',
			position: 'absolute',
			height: 1,
			width: '50vw',
			background: '#D9D9D9',
		},
	},
}));

const Info: React.FC<Omit<BoxProps, 'ref'>> = ({ px, ...rest }) => {
	const isMobile = React.useContext(MobileContext);
	return (
		<ContentWrapper>
			<Wrapper {...rest} px={isMobile ? px : undefined}>
				<Row spacing={2} justifyContent="center">
					<Col xs={12} md={6}>
						<TheRow spacing={2} className="left-row">
							<Col>
								<Image
									src="/images/72hr_icon.png"
									alt="72hr icon"
									height={116}
									width={116}
								/>
							</Col>
							<Col>
								<InfoText>
									até 72h de hidratação vaginal
								</InfoText>
							</Col>
						</TheRow>
						<DescWrap>
							<Text>
								KY® Hidratante Intravaginal forma uma barreira
								física na parede da mucosa vaginal que
								proporciona uma sensação de lubrificação
								natural, promovendo alívio imediato do
								ressecamento e hidratação por até 72 horas a
								cada aplicação.
							</Text>
						</DescWrap>
					</Col>
					<TheCol xs={12} md={6}>
						<TheRow spacing={2} className="right-row">
							<Col>
								<Image
									src="/images/ph_icon.png"
									alt="72hr icon"
									height={116}
									width={116}
								/>
							</Col>
							<Col>
								<InfoText>
									ajuda a manter o pH entre 3,8 e 4,5
								</InfoText>
							</Col>
						</TheRow>
						<DescWrap>
							<Text>
								Contém ácido lático, também encontrado na mucosa
								vaginal, que ajuda a manter o pH regular da área
								íntima (entre 3,8 - 4,5), e vitamina E, que traz
								sensação de conforto, proteção e bem-estar todos
								os dias.
							</Text>
						</DescWrap>
					</TheCol>
				</Row>
			</Wrapper>
		</ContentWrapper>
	);
};

export default Info;
