import React from 'react';
import { AnimatedProps, animated, useInView } from '@react-spring/web';

import { useTheme } from '@ui/styles/hooks';
import { styled } from '@ui/styles';
import { IconProps } from '@ui/icons/props';

const SVG = styled(animated.svg)(({ theme }) => ({
	position: 'absolute',
	[theme.breakpoints.down('sm')]: {
		left: 'auto',
		right: 60,
		bottom: 20,
		top: 'auto',
		width: 91,
		height: 88,
	},
	[theme.breakpoints.up('sm')]: {
		left: 'auto',
		top: 'auto',
		right: '32%',
		bottom: theme.spacing(10),
	},
}));
const ValidSeal: React.FC<
	AnimatedProps<Omit<React.SVGProps<SVGSVGElement>, 'ref'>> &
		Pick<IconProps, 'color' | 'htmlColor'>
> = ({ color, htmlColor, ...rest }) => {
	const [ref, springs] = useInView(() => ({
		from: {
			opacity: 0,
			y: 100,
			scale: 1.5,
		},
		to: {
			opacity: 1,
			y: 0,
			scale: 1,
		},
		delay: 1000,
	}));
	const palette: any = useTheme().palette;
	const themeColor =
		htmlColor || (color && palette[color]?.main) || '#ffffff';
	return (
		<SVG
			width="201"
			height="198"
			viewBox="0 0 201 198"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
			ref={ref}
			style={springs}
		>
			<g clipPath="url(#clip0_720_547)">
				<path
					d="M46.7838 50.3134L44.2074 53.4577L48.9855 57.3788L46.9001 59.921L33.5273 48.9525L38.1391 43.325C40.4299 40.5305 43.4539 40.3021 46.0737 42.4537C48.5577 44.4951 48.9458 47.6799 46.7804 50.3194L46.7838 50.3134ZM37.9836 48.3505L41.8736 51.5431L44.385 48.477C45.3499 47.3026 45.3354 46.0972 43.9871 44.9912C42.6388 43.8852 41.4539 44.1065 40.495 45.2843L37.9836 48.3505V48.3505Z"
					fill={themeColor}
				/>
				<path
					d="M60.69 36.6197L58.1332 38.6861L57.7253 38.1832C57.2557 37.6006 55.9534 36.3457 54.2653 37.7087C53.1219 38.6324 53.0169 40.1279 53.9259 41.2554L58.6404 47.0909L56.0837 49.1573L48.0163 39.1706L50.4561 37.1963L51.2416 38.1648C51.2699 36.8923 51.7656 35.7222 52.8341 34.8591C55.1793 32.9658 58.1529 33.4744 60.2855 36.1108L60.6934 36.6137L60.69 36.6197Z"
					fill={themeColor}
				/>
				<path
					d="M77.2271 35.5583L74.4272 36.9814L73.807 35.7613C73.3943 37.1469 72.4445 38.302 71.0415 39.0119C68.0643 40.5281 64.29 39.2019 62.5714 35.8271C60.8418 32.43 61.994 28.5947 64.9725 27.0832C66.3695 26.3698 67.8635 26.2832 69.2262 26.7631L68.6059 25.5429L71.4058 24.1197L77.2284 35.563L77.2271 35.5583ZM66.8004 29.5634C65.1801 30.3872 64.5921 32.4775 65.5421 34.343C66.47 36.1638 68.4923 36.8969 70.1125 36.0732C71.7328 35.2495 72.3097 33.1368 71.4039 31.3607C70.476 29.5399 68.4206 28.7397 66.8004 29.5634Z"
					fill={themeColor}
				/>
				<path
					d="M89.217 32.0977L78.4341 35.1742L77.6567 32.4428L82.2464 23.8258L75.9922 25.6079L75.1772 22.755L85.4813 19.817L86.29 22.6462L81.793 31.1372L88.408 29.2483L89.223 32.1012L89.217 32.0977Z"
					fill={themeColor}
				/>
				<path
					d="M92.6376 26.5364C93.1897 28.0683 94.5138 28.9329 96.0521 28.804C97.1456 28.7134 98.173 28.1497 98.6326 27.0905L102.135 26.7996C101.607 29.471 99.4952 31.4232 96.319 31.6874C92.718 31.9894 89.5171 29.2547 89.2033 25.5542C88.8941 21.8523 91.5928 18.5791 95.1938 18.2771C98.9688 17.9638 102.339 20.6328 102.29 25.736L92.6341 26.5424L92.6376 26.5364ZM98.8153 23.3176C98.2929 21.8587 96.9717 21.0237 95.4084 21.1542C93.9197 21.2799 92.808 22.3215 92.4594 23.8519L98.8166 23.3223L98.8153 23.3176Z"
					fill={themeColor}
				/>
				<path
					d="M116.392 25.55L113.126 25.1601L113.202 24.5175C113.291 23.775 113.282 21.9662 111.129 21.7082C109.671 21.5322 108.525 22.5021 108.352 23.9347L107.46 31.3838L104.195 30.9938L105.721 18.2493L108.841 18.6227L108.692 19.8616C109.623 18.9949 110.81 18.5352 112.169 18.6975C115.162 19.054 116.872 21.5457 116.469 24.9121L116.393 25.5547L116.392 25.55Z"
					fill={themeColor}
				/>
				<path
					d="M134.321 39.5375L131.33 38.1685L134.448 31.3473C134.757 30.6676 135.301 28.9431 133.329 28.0395C131.992 27.4265 130.608 27.9997 130.009 29.3137L126.89 36.135L123.899 34.766L129.233 23.0958L132.086 24.4008L131.57 25.5309C132.719 24.99 133.988 24.9131 135.233 25.4803C137.976 26.7337 138.843 29.6283 137.437 32.7068L134.319 39.528L134.321 39.5375Z"
					fill={themeColor}
				/>
				<path
					d="M146.086 47.9768L143.57 46.1008L144.388 45.0012C142.962 45.2468 141.505 44.9036 140.247 43.9656C137.568 41.9714 137.08 37.9987 139.341 34.9593C141.617 31.9006 145.566 31.2371 148.24 33.2326C149.498 34.1706 150.24 35.4693 150.413 36.9056L151.231 35.806L153.746 37.682L146.08 47.9734L146.086 47.9768ZM146.824 35.9698C145.364 34.8834 143.236 35.2818 141.982 36.9636C140.762 38.601 141.002 40.7427 142.461 41.8291C143.921 42.9155 146.064 42.4979 147.259 40.8977C148.479 39.2603 148.284 37.0609 146.825 35.9745L146.824 35.9698ZM150.937 33.1626C149.78 32.2987 149.471 30.9193 148.689 30.3396C148.228 29.9976 147.752 30.0139 147.181 30.4905L145.943 29.5676C147.017 28.5004 148.573 27.8307 149.912 28.8278C151.069 29.6916 151.344 31.0753 152.083 31.626C152.544 31.968 152.962 31.9065 153.567 31.4257L154.805 32.3486C153.74 33.4488 152.195 34.1006 150.937 33.1626V33.1626Z"
					fill={themeColor}
				/>
				<path
					d="M160.503 54.4811C157.613 57.0011 153.533 56.6876 151.195 54.0001C148.852 51.3139 149.113 47.2516 152.003 44.7269C154.892 42.2021 158.994 42.469 161.333 45.1564C163.676 47.8427 163.392 51.9563 160.503 54.4811V54.4811ZM158.306 51.9987C159.978 50.5386 160.208 48.4531 159.062 47.141C157.916 45.8241 155.817 45.7691 154.145 47.2291C152.509 48.6542 152.316 50.7095 153.466 52.0203C154.613 53.3372 156.674 53.4225 158.306 51.9987V51.9987Z"
					fill={themeColor}
				/>
				<path
					d="M169.457 74.213C166.483 75.4956 163.397 74.4353 162.035 71.2786C160.673 68.1219 162.015 65.1263 164.989 63.8436L174.85 59.5924L176.151 62.6138L173.154 63.9074L176.271 71.1424L173.432 72.3635L170.315 65.1285L166.131 66.9329C165.056 67.3978 164.243 68.5317 164.895 70.0417C165.536 71.5294 166.923 71.7196 167.997 71.2548L169.117 70.7725L170.417 73.7939L169.456 74.2083L169.457 74.213Z"
					fill={themeColor}
				/>
				<path
					d="M170.586 79.37C169.14 80.1066 168.443 81.5278 168.763 83.0411C168.989 84.1137 169.674 85.0637 170.787 85.3877L171.516 88.8252C168.8 88.6371 166.599 86.7831 165.94 83.6609C165.189 80.1229 167.503 76.6037 171.135 75.8328C174.769 75.0667 178.356 77.3385 179.106 80.8717C179.891 84.5774 177.662 88.2613 172.595 88.8498L170.589 79.364L170.586 79.37ZM174.55 85.103C175.93 84.4042 176.597 82.9859 176.273 81.4538C175.961 79.989 174.79 79.0175 173.226 78.8599L174.545 85.1043L174.55 85.103Z"
					fill={themeColor}
				/>
				<path
					d="M166.991 110.782L167.178 107.499L174.667 107.926C175.412 107.97 177.219 107.824 177.338 105.683C177.42 104.214 176.386 103.13 174.944 103.051L167.455 102.623L167.641 99.3151L175.129 99.7423C175.874 99.7856 177.677 99.6618 177.8 97.4986C177.884 96.0545 176.845 94.9717 175.403 94.8927L167.914 94.4655L168.1 91.1573L180.91 91.8891L180.73 95.049L179.487 94.9773C180.412 95.8272 180.945 96.9848 180.868 98.3767C180.779 99.9691 180.03 101.273 178.832 102.105C179.9 103.089 180.518 104.471 180.428 106.115C180.23 109.547 177.831 111.41 174.471 111.217L166.983 110.79L166.991 110.782Z"
					fill={themeColor}
				/>
				<path
					d="M160.834 125.744C161.459 124.413 162.533 123.457 163.806 122.98L157.508 120.032L158.914 117.03L175.706 124.886L174.366 127.755L173.146 127.186C173.651 128.523 173.634 130.025 172.978 131.426C171.554 134.474 167.775 135.734 164.345 134.128C160.893 132.513 159.41 128.792 160.835 125.749L160.834 125.744ZM170.4 129.698C171.172 128.049 170.328 126.029 168.519 125.188C166.692 124.331 164.581 124.968 163.809 126.617C163.037 128.265 163.84 130.261 165.737 131.15C167.61 132.024 169.634 131.35 170.402 129.702L170.4 129.698Z"
					fill={themeColor}
				/>
				<path
					d="M156.234 145.799L158.204 143.166L158.723 143.553C159.318 144.001 160.889 144.9 162.19 143.166C163.073 141.988 162.81 140.515 161.651 139.647L155.65 135.143L157.62 132.51L167.882 140.213L166.002 142.726L165.007 141.976C165.288 143.216 165.094 144.472 164.275 145.567C162.468 147.979 159.456 148.214 156.746 146.178L156.227 145.79L156.234 145.799Z"
					fill={themeColor}
				/>
				<path
					d="M140.468 150.909L142.827 148.84L143.731 149.873C143.79 148.43 144.428 147.075 145.607 146.041C148.118 143.84 152.102 144.194 154.6 147.041C157.113 149.91 156.936 153.909 154.425 156.109C153.246 157.144 151.819 157.597 150.383 157.466L151.287 158.499L148.928 160.568L140.47 150.913L140.468 150.909ZM152.048 154.146C153.415 152.945 153.472 150.779 152.088 149.202C150.741 147.665 148.601 147.454 147.232 148.651C145.864 149.848 145.828 152.038 147.141 153.538C148.488 155.074 150.674 155.344 152.043 154.147L152.048 154.146Z"
					fill={themeColor}
				/>
				<path
					d="M129.671 157.177L139.4 151.605L140.814 154.069L138.426 163.539L144.069 160.306L145.543 162.881L136.247 168.206L134.784 165.653L137.113 156.336L131.146 159.757L129.672 157.181L129.671 157.177Z"
					fill={themeColor}
				/>
				<path
					d="M119.047 167.719C117.806 164.085 119.639 160.431 123.006 159.276C126.373 158.121 130.042 159.901 131.283 163.535C132.525 167.169 130.733 170.862 127.36 172.014C123.986 173.165 120.288 171.348 119.043 167.72L119.047 167.719ZM122.178 166.622C122.897 168.721 124.739 169.726 126.388 169.162C128.038 168.599 128.883 166.677 128.164 164.578C127.462 162.525 125.634 161.572 123.985 162.136C122.335 162.699 121.472 164.575 122.174 166.628L122.178 166.622Z"
					fill={themeColor}
				/>
				<path
					d="M96.021 163.193L99.1598 163.166L99.1735 164.539C100.185 163.484 101.53 162.875 103.098 162.86C106.439 162.83 109.226 165.701 109.26 169.492C109.29 173.304 106.551 176.218 103.211 176.248C101.717 176.259 100.415 175.722 99.4096 174.782L99.4712 181.713L96.1808 181.739L96.021 163.193V163.193ZM102.685 173.207C104.501 173.19 105.962 171.585 105.942 169.49C105.925 167.445 104.441 165.89 102.624 165.901C100.806 165.913 99.3477 167.548 99.366 169.542C99.3828 171.587 100.867 173.218 102.685 173.207Z"
					fill={themeColor}
				/>
				<path
					d="M90.2671 167.426C90.1756 165.801 89.155 164.597 87.6434 164.279C86.5719 164.055 85.423 164.297 84.6796 165.184L81.239 164.462C82.5053 162.053 85.0899 160.789 88.2141 161.444C91.751 162.187 94.0387 165.723 93.2731 169.358C92.5121 172.993 88.984 175.36 85.4471 174.617C81.7389 173.84 79.2725 170.317 80.7767 165.436L90.2624 167.428L90.2671 167.426ZM83.4219 168.739C83.5073 170.285 84.5323 171.468 86.0653 171.79C87.528 172.097 88.8936 171.417 89.6656 170.052L83.4219 168.739V168.739Z"
					fill={themeColor}
				/>
				<path
					d="M67.7039 153.719L70.7849 155.459L68.7048 169.024L65.7123 167.332L67.6411 157.429L60.139 164.18L57.1885 162.512L67.7087 153.718L67.7039 153.719Z"
					fill={themeColor}
				/>
				<path
					d="M53.8183 142.969L56.1947 145.024L55.2986 146.058C56.7384 145.92 58.1621 146.364 59.3526 147.391C61.8806 149.577 62.0809 153.571 59.6017 156.436C57.1074 159.321 53.1273 159.699 50.5993 157.513C49.4134 156.485 48.7637 155.136 48.6954 153.692L47.7993 154.726L45.4228 152.671L53.817 142.964L53.8183 142.969ZM52.2128 154.89C53.5886 156.079 55.7437 155.836 57.114 154.249C58.4494 152.702 58.3645 150.555 56.9887 149.365C55.6129 148.175 53.4427 148.438 52.1375 149.946C50.802 151.493 50.837 153.7 52.2128 154.89V154.89Z"
					fill={themeColor}
				/>
				<path
					d="M50.2089 138.446L52.3336 140.958L38.178 152.928L36.0534 150.416L50.2089 138.446Z"
					fill={themeColor}
				/>
				<path
					d="M35.0013 141.732L36.8826 144.431L34.3692 146.187L32.4879 143.488L35.0013 141.732ZM46.6666 133.587L48.5479 136.286L38.0293 143.633L36.148 140.934L46.6666 133.587Z"
					fill={themeColor}
				/>
				<path
					d="M39.6767 120.468L41.0458 123.294L39.8152 123.892C41.2036 124.355 42.3277 125.303 43.0152 126.718C44.4727 129.722 43.081 133.475 39.6747 135.127C36.2473 136.794 32.4391 135.568 30.9816 132.563C30.3284 131.22 30.2548 129.813 30.6723 128.502L24.4427 131.527L23.0073 128.567L39.6827 120.472L39.6767 120.468ZM33.4947 130.787C34.2879 132.426 36.3664 133.048 38.2458 132.135C40.0853 131.242 40.8525 129.235 40.0592 127.596C39.2659 125.957 37.1651 125.346 35.3703 126.216C33.5308 127.109 32.6967 129.149 33.4899 130.788L33.4947 130.787Z"
					fill={themeColor}
				/>
				<path
					d="M36.081 106.313L36.8034 109.368L35.4721 109.684C36.7192 110.413 37.6164 111.609 37.98 113.136C38.7495 116.385 36.5749 119.746 32.8888 120.617C29.179 121.495 25.7282 119.47 24.9587 116.22C24.5951 114.693 24.8629 113.22 25.6506 112.007L24.3192 112.323L23.5967 109.269L36.0798 106.308L36.081 106.313ZM27.8029 115.038C28.2216 116.808 30.1143 117.869 32.1504 117.39C34.138 116.918 35.3271 115.127 34.9085 113.357C34.4898 111.587 32.5723 110.527 30.6331 110.991C28.6454 111.463 27.3856 113.273 27.8042 115.043L27.8029 115.038Z"
					fill={themeColor}
				/>
				<path
					d="M35.6436 91.7319L35.6814 94.8739L34.3106 94.8921C35.3705 95.9034 35.9869 97.241 36.0028 98.8103C36.0427 102.149 33.1879 104.953 29.4025 105.001C25.5909 105.046 22.6651 102.318 22.624 98.9748C22.6069 97.4766 23.1383 96.1755 24.0717 95.1665L17.1453 95.2515L17.1039 91.9637L35.6402 91.7378L35.6436 91.7319ZM25.6604 98.4344C25.6829 100.255 27.2954 101.705 29.3878 101.681C31.4292 101.655 32.9825 100.166 32.96 98.3455C32.9375 96.525 31.3001 95.0764 29.3084 95.0991C27.267 95.1249 25.6379 96.6138 25.6604 98.4344V98.4344Z"
					fill={themeColor}
				/>
				<path
					d="M31.3924 85.9645C33.0155 85.8686 34.2175 84.8432 34.5296 83.3277C34.7533 82.2558 34.5016 81.1089 33.6144 80.3651L34.3253 76.9212C36.7368 78.1825 38.0094 80.761 37.3702 83.8871C36.6397 87.4273 33.114 89.7281 29.4785 88.9768C25.8431 88.2256 23.4645 84.7089 24.195 81.1687C24.9606 77.4572 28.4738 74.9777 33.3541 76.468L31.3971 85.9632L31.3924 85.9645ZM30.0549 79.123C28.5111 79.2128 27.3304 80.2426 27.0139 81.7795C26.714 83.2462 27.3971 84.6064 28.7658 85.373L30.0549 79.123Z"
					fill={themeColor}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M66.3523 93.3031L71.7273 91.8629L74.9004 103.705L75.204 103.624L87.921 87.5238L96.1184 85.295L81.3738 103.575L103.411 112.082L95.1977 114.25L76.0461 106.629L75.7082 106.72L78.9039 118.646L73.5289 120.087L66.3523 93.3031Z"
					fill={themeColor}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M120.35 89.4357L130.559 76.099L138.789 73.8615L123.951 92.2657L127.528 105.618L122.153 107.058L118.581 93.7275L96.5232 85.1865L104.737 83.018L120.35 89.4357Z"
					fill={themeColor}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M108.148 96.0934L101.793 103.9L92.3848 100.317L98.7416 92.5102L108.148 96.0934Z"
					fill={themeColor}
				/>
			</g>
			<defs>
				<clipPath id="clip0_720_547">
					<rect
						width="165"
						height="160"
						fill={themeColor}
						transform="translate(0 42.7217) rotate(-15)"
					/>
				</clipPath>
			</defs>
		</SVG>
	);
};

export default ValidSeal;
