import React from 'react';
import { AnimatedProps, animated, useInView } from '@react-spring/web';

import { BoxProps, Box, Text, Row, Col } from '@ui/components';
import { styled } from '@ui/styles';
import { BulletDimond } from '@ui/icons';

import MobileContext from '@web/components/MobileContext';

import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import ContentBox from '@common-lib/components/Content/ContentBox';

import ApprovedSeal from './ApprovedSeal';

const ItemBG = styled(animated.div)(({ theme }) => ({
	position: 'absolute',
	maxHeight: 584,
	minHeight: 584,
	minWidth: 412,
	maxWidth: 412,
	background: "url('/images/ky_clinical_item.png')",
	[theme.breakpoints.down('sm')]: {
		top: -100,
		right: 0,
		maxHeight: 360,
		minHeight: 360,
		minWidth: 215,
		maxWidth: 215,
	},
	[theme.breakpoints.up('sm')]: {
		top: -60,
		right: 0,
	},
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
}));
const MoisturizerItem: React.FC<
	AnimatedProps<
		Omit<
			React.DetailedHTMLProps<
				React.HTMLAttributes<HTMLDivElement>,
				HTMLDivElement
			>,
			'ref'
		>
	>
> = props => {
	const isMobile = React.useContext(MobileContext);
	const [ref, springs] = useInView(() =>
		isMobile
			? {
					from: {
						rotate: 112,
						right: -20,
					},
					to: {
						rotate: 112,
						right: 64,
					},
			  }
			: {
					from: { opacity: 0, right: -200, rotate: 112 },
					to: {
						opacity: 1,
						right: -64,
						rotate: 112,
					},
					delay: 1000,
			  },
	);

	return <ItemBG ref={ref} style={springs} {...props} />;
};

const Root = styled(ContentWrapper)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		overflow: 'hidden',
	},
}));
const Wrapper = styled(ContentBox)(({ theme }) => ({
	position: 'relative',
	[theme.breakpoints.up('sm')]: {
		paddingTop: theme.spacing(21),
		paddingBottom: theme.spacing(12),
	},
	[theme.breakpoints.down('sm')]: {
		paddingTop: theme.spacing(36),
		paddingBottom: theme.spacing(15),
		overflow: 'hidden',
	},
}));
const Heading = styled(Text)(({ theme }) => ({
	fontSize: '3rem',
	lineHeight: '3rem',
	fontWeight: '700',
	fontFamily: theme.fontFamily.chalet,
	[theme.breakpoints.down('sm')]: {
		fontSize: '2.5rem',
		lineHeight: '2.5rem',
	},
}));
const HeadingWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	'& > svg': {
		width: 10,
		height: 7,
		top: 8,
		position: 'absolute',
		left: 0,
	},
	paddingLeft: 17,
	marginLeft: 10,
	[theme.breakpoints.up('sm')]: {
		marginBottom: theme.spacing(10),
		maxWidth: 358,
	},
	[theme.breakpoints.down('sm')]: {
		marginBottom: theme.spacing(3),
	},
	'&.mb-2': {
		marginBottom: theme.spacing(2),
	},
}));

const TextLine = styled(Text)(({ theme }) => ({
	fontFamily: theme.fontFamily.chalet,
	fontSize: '1.25rem',
	lineHeight: '1.5rem',
}));
const TheRow = styled(Row)(({ theme }) => ({
	position: 'relative',
	alignItems: 'center',
	[theme.breakpoints.up('sm')]: {
		paddingTop: 108,
		zIndex: 2,
	},
	[theme.breakpoints.down('sm')]: {
		paddingTop: theme.spacing(5),
		zIndex: 2,
	},
}));

const Formulation: React.FC<Omit<BoxProps, 'ref'>> = ({ px, ...rest }) => {
	const isMobile = React.useContext(MobileContext);
	return (
		<Root>
			<Wrapper {...rest} px={isMobile ? px : undefined}>
				<Heading color="primary">
					Formulação <br />e embalagem
				</Heading>
				<TheRow>
					<Col xs={12} md={2} lg={3} position="relative">
						<Box ml={-2} position="relative">
							<ApprovedSeal />
						</Box>
					</Col>
					<Col xs={12} md={8} lg={9}>
						<HeadingWrapper>
							<BulletDimond htmlColor="black" />
							<TextLine>
								Formulado para minimizar o risco de escorrer
							</TextLine>
						</HeadingWrapper>
						<HeadingWrapper>
							<BulletDimond htmlColor="black" />
							<TextLine>
								Sem parabenos, fragrância e hormônios
							</TextLine>
						</HeadingWrapper>
						<Row>
							<Col>
								<HeadingWrapper>
									<BulletDimond htmlColor="black" />
									<TextLine>Hipoalergênico</TextLine>
								</HeadingWrapper>
							</Col>
							<Col>
								<HeadingWrapper>
									<BulletDimond htmlColor="black" />
									<TextLine>
										Dermalotogicamente testado
									</TextLine>
								</HeadingWrapper>
							</Col>
							<Col>
								<HeadingWrapper>
									<BulletDimond htmlColor="black" />
									<TextLine>
										Ginecologicamente testado
									</TextLine>
								</HeadingWrapper>
							</Col>
						</Row>

						<HeadingWrapper>
							<BulletDimond htmlColor="black" />
							<TextLine>
								Contém aplicadores com trava para garantir a
								dosagem correta
							</TextLine>
						</HeadingWrapper>
						<HeadingWrapper>
							<BulletDimond htmlColor="black" />
							<TextLine>
								10 aplicações, uma aplicação a cada 3 dias, para
								30 dias de tratamento
							</TextLine>
						</HeadingWrapper>
					</Col>
				</TheRow>
			</Wrapper>
			<MoisturizerItem />
		</Root>
	);
};

export default Formulation;
