import React from 'react';
import { AnimatedProps, animated, useInView } from '@react-spring/web';

import { useTheme } from '@ui/styles/hooks';
import { styled } from '@ui/styles';
import { IconProps } from '@ui/icons/props';

const SVG = styled(animated.svg)(({ theme }) => ({
	position: 'absolute',
	[theme.breakpoints.down('sm')]: {
		left: 'auto',
		right: 20,
		top: 0,
		height: 250,
	},
	[theme.breakpoints.up('sm')]: {
		left: 'auto',
		top: 95,
		right: 50,
		bottom: theme.spacing(10),
	},
}));
const Clinical: React.FC<
	AnimatedProps<Omit<React.SVGProps<SVGSVGElement>, 'ref'>> &
		Pick<IconProps, 'color' | 'htmlColor'>
> = ({ color, htmlColor, ...rest }) => {
	const [ref, springs] = useInView(() => ({
		from: {
			opacity: 0,
			y: 100,
			scale: 1.5,
		},
		to: {
			opacity: 1,
			y: 0,
			scale: 1,
		},
		delay: 1000,
	}));
	const palette: any = useTheme().palette;
	const themeColor =
		htmlColor || (color && palette[color]?.main) || '#D9D9D9';
	return (
		<SVG
			width="20"
			height="358"
			viewBox="0 0 20 358"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
			ref={ref}
			style={springs}
		>
			<path
				d="M13.5912 338.806L13.5912 342.779C15.3315 343.646 16.6851 345.408 16.6851 347.618C16.6851 350.807 13.6464 353.324 10 353.324C6.38122 353.324 3.34254 350.807 3.34254 347.618C3.34254 345.38 4.64089 343.646 6.38122 342.779L6.38122 338.779C2.45856 339.981 -6.028e-07 343.226 -4.10833e-07 347.618C-1.7974e-07 352.905 4.39227 357.017 10 357.017C15.6077 357.017 20 352.905 20 347.618C20 343.31 17.4862 340.037 13.5912 338.806Z"
				fill={themeColor}
			/>
			<path
				d="M0.497236 299.057L0.497236 302.778L19.6685 302.778L19.6685 289.407L16.2707 289.407L16.2707 299.057L0.497236 299.057Z"
				fill={themeColor}
			/>
			<path
				d="M0.497233 249.809L0.497234 253.501L19.6685 253.501L19.6685 249.809L0.497233 249.809Z"
				fill={themeColor}
			/>
			<path
				d="M0.497231 196.559L0.497231 200.279L14.1436 200.279L0.497232 208.168L0.497232 212.615L19.6685 212.615L19.6685 208.923L6.0221 208.923L19.6685 201.007L19.6685 196.559L0.497231 196.559Z"
				fill={themeColor}
			/>
			<path
				d="M0.497229 155.631L0.497229 159.324L19.6685 159.324L19.6685 155.631L0.497229 155.631Z"
				fill={themeColor}
			/>
			<path
				d="M13.5912 101.095L13.5912 105.067C15.3315 105.934 16.6851 107.696 16.6851 109.906C16.6851 113.095 13.6464 115.612 10 115.612C6.38121 115.612 3.34253 113.095 3.34253 109.906C3.34253 107.668 4.64088 105.934 6.38121 105.067L6.38121 101.067C2.45855 102.269 -1.09935e-05 105.514 -1.08016e-05 109.906C-1.05705e-05 115.193 4.39226 119.305 10 119.305C15.6077 119.305 20 115.193 20 109.906C20 105.598 17.4862 102.325 13.5912 101.095Z"
				fill={themeColor}
			/>
			<path
				d="M15.8287 54.2551L19.6685 52.9405L19.6685 49.0243L0.497225 55.7936L0.497225 60.465L19.6685 67.2344L19.6685 63.3182L15.8287 62.0035L15.8287 54.2551ZM12.4309 55.43L12.4309 60.8567L4.558 58.1433L12.4309 55.43Z"
				fill={themeColor}
			/>
			<path
				d="M0.497223 9.66711L0.497223 13.3875L19.6685 13.3875L19.6685 0.0166007L16.2707 0.0166009L16.2707 9.66711L0.497223 9.66711Z"
				fill={themeColor}
			/>
		</SVG>
	);
};

export default Clinical;
