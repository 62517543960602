import React from 'react';
import dynamic from 'next/dynamic';

import { Box, BoxProps } from '@ui/components';

import MobileContext from '@web/components/MobileContext';

const Mobile = dynamic(() => import('./Mobile'));

const Desktop = dynamic(() => import('./Desktop'));

const Difference: React.FC<Omit<BoxProps, 'ref'>> = props => {
	const isMobile = React.useContext(MobileContext);
	return <>{isMobile ? <Mobile {...props} /> : <Desktop {...props} />}</>;
};

export default Difference;
