import React from 'react';
import { animated, useInView } from '@react-spring/web';

import { styled } from '@ui/styles';
import { BoxProps, Box, Text } from '@ui/components';

import MobileContext from '@web/components/MobileContext';

import Slider from '@common-lib/modules/brands/components/ky-clinical/Slider';
import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import ContentBox from '@common-lib/components/Content/ContentBox';

const Root = styled(ContentWrapper)(({ theme }) => ({
	position: 'relative',
	background: '#FEE5E9',
}));

const Wrapper = styled(ContentBox)(({ theme }) => ({
	position: 'relative',
	[theme.breakpoints.up('sm')]: {
		paddingBottom: theme.spacing(25.75),
	},
	[theme.breakpoints.down('sm')]: {
		paddingBottom: theme.spacing(15),
	},
}));

const Title = styled(animated.div)(({ theme }) => ({
	fontSize: '5.625rem',
	lineHeight: '5.625rem',
	fontFamily: theme.fontFamily.chalet,
	color: theme.palette.primary.contrastText,
	[theme.breakpoints.down('sm')]: {
		maxWidth: '100%',
		fontSize: '3rem',
		lineHeight: '3rem',
	},
	'@supports not (-webkit-text-stroke: inherit)': {
		color: '#9A4856',
	},
}));
const DescText = styled(Text)(({ theme }) => ({
	fontSize: '1rem',
	lineHeight: '1.5rem',
	paddingTop: theme.spacing(4),
	fontFamily: theme.fontFamily.mulish,
	[theme.breakpoints.up('sm')]: {
		maxWidth: 547,
	},
}));
const SubHead = styled(Text)(({ theme }) => ({
	fontSize: '1.5rem',
	lineHeight: '1.5rem',
	fontWeight: '700',
	fontFamily: theme.fontFamily.mulish,
	paddingTop: theme.spacing(4),
	[theme.breakpoints.up('sm')]: {
		paddingTop: theme.spacing(8),
	},
}));

const OulinedText: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [ref, prop] = useInView(() => ({
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0,
		},
		config: {
			duration: 1000,
		},
	}));
	return (
		<Title
			ref={ref}
			style={{
				WebkitTextFillColor: prop.opacity.to(
					v => `rgba(154, 72, 86, ${v})`,
				),
				WebkitTextStroke: prop.opacity
					.to([1, 0], [0, 1])
					.to(v => `1px rgba(154, 72, 86, ${v})`),
			}}
		>
			{children}
		</Title>
	);
};

const BG = styled(Box)(({ theme }) => ({
	position: 'absolute',
	paddingTop: theme.spacing(9),
	backgroundImage: "url('/images/ky_assesment_item.png')",
	backgroundSize: 'cover',
	width: 423,
	height: 792,
	bottom: -60,
	right: 0,
	zIndex: 1,
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}));

const Assessments: React.FC<Omit<BoxProps, 'ref'>> = ({ px, ...rest }) => {
	const isMobile = React.useContext(MobileContext);
	return (
		<Root>
			<Wrapper {...rest}>
				<Box px={isMobile ? px : undefined}>
					<OulinedText>Avaliações</OulinedText>
					<DescText color="primary">
						O produto foi testado clinicamente e avaliado por grupos
						de voluntárias com acompanhamento médico:
					</DescText>
					<SubHead color="primary" pt={9}>
						Alguns comentários espontâneos das voluntárias:
					</SubHead>
				</Box>
				<Slider px={isMobile ? px : undefined} />
			</Wrapper>
			<BG />
		</Root>
	);
};

export default Assessments;
