import React from 'react';

import { Box, BoxProps, Image, Text } from '@ui/components';
import { styled } from '@ui/styles';

import MobileContext from '@web/components/MobileContext';

import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import ContentBox from '@common-lib/components/Content/ContentBox';

import ValidSeal from './ValidSeal';

const Wrapper = styled(ContentBox)(({ theme }) => ({
	maxHeight: 896,
	minHeight: 896,
	display: 'flex',
	position: 'relative',
	[theme.breakpoints.down('sm')]: {
		minHeight: '100%',
		maxHeight: '100%',
	},
	// overflow: 'hidden',
}));
const Content = styled(Box)(({ theme }) => ({
	display: 'flex',
	position: 'relative',
	flexDirection: 'column',
	justifyContent: 'center',
}));
const TextData = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	zIndex: 1,
	[theme.breakpoints.down('sm')]: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(6),
	},
}));
const BG = styled(Box)(({ theme }) => ({
	background: "url('/images/ky_intro_girl.png')",
	maxHeight: 874,
	minHeight: 874,
	minWidth: 1329,
	maxWidth: 1329,
	position: 'absolute',
	bottom: 0,
	left: 'auto',
	right: '-42%',
	[theme.breakpoints.down('sm')]: {
		right: '-5%',
		minWidth: '100%',
		maxWidth: '100%',
		maxHeight: '100%',
		minHeight: '100%',
	},
	backgroundSize: 'cover',
}));
const Title = styled(Text)(({ theme }) => ({
	fontSize: '5.625rem',
	lineHeight: '5.625rem',
	fontFamily: theme.fontFamily.chalet,
	color: theme.palette.primary.contrastText,
	[theme.breakpoints.down('sm')]: {
		maxWidth: '100%',
		fontSize: '2.5rem',
		lineHeight: '2.5rem',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
}));
const Desc = styled(Text)(({ theme }) => ({
	fontSize: '1rem',
	lineHeight: '1.5rem',
	fontFamily: theme.fontFamily.mulish,
	[theme.breakpoints.up('sm')]: {
		maxWidth: 348,
	},
	[theme.breakpoints.down('sm')]: {
		maxWidth: '100%',
	},
}));
const MobileBG = styled(Box)(({ theme }) => ({
	top: 80,
	position: 'absolute',
	background: "url('/images/ky-intro_bg.png')",
	width: 375,
	height: 423,
}));

const Root = styled(ContentWrapper)(({ theme }) => ({
	background: 'linear-gradient(129.75deg, #F7D2D6 4.76%, #FFEAEC 72.7%)',
	// zIndex: 1,
}));

const Intro: React.FC<Omit<BoxProps, 'ref'>> = ({ px, ...props }) => {
	const isMobile = React.useContext(MobileContext);
	return (
		<Root>
			{isMobile ? <MobileBG /> : null}
			<Wrapper {...props}>
				{isMobile ? null : <BG />}
				<Content position="relative" px={isMobile ? px : undefined}>
					<TextData>
						<Title>KY® Clinical Hidratante Intravaginal</Title>
						<Desc pt={4}>
							Há mais de 30 anos, a marca líder em lubrificantes
							no mundo, atua como principal parceira no auxílio à
							melhora da lubrificação íntima.
							<br />
							<br /> Partindo do princípio de que lubrificação
							íntima vai além do prazer e deve promover bem-estar
							todos os dias, lançamos KY® Clinical Hidratante
							Intravaginal com o objetivo de tratar disfunções
							específicas que podem causar desconforto e ajudar a
							recuperar o equilíbrio natural da mucosa vaginal.
							<br />
							<br /> KY® Clinical Hidratante Intravaginal já está
							sendo trabalhado com muito esforço também na
							comunidade médica.
						</Desc>
					</TextData>
					{isMobile ? (
						<Box position="relative" height={310} width="100%">
							<Image
								src="/images/ky_intro_girl2.png"
								alt="intro girl"
								fill
								sizes="any"
							/>
						</Box>
					) : null}
				</Content>
				<ValidSeal />
			</Wrapper>
		</Root>
	);
};

export default Intro;
