import React from 'react';

import { useTheme } from '@ui/styles/hooks';
import { IconProps } from '@ui/icons/props';
import { Box, BoxProps } from '@ui/components';
import { styled } from '@ui/styles';
import { useWidth } from '@ui/hooks';

const Wrapper = styled(Box)(({ theme }) => ({
	background: '#F4CBD3',
	paddingTop: theme.spacing(3.5),
	paddingBottom: theme.spacing(3.5),
	border: '1px solid rgba(154, 72, 86, 0.3)',
	[theme.breakpoints.down('sm')]: {
		paddingTop: theme.spacing(2.5),
		paddingBottom: theme.spacing(1.75),
	},
}));
const Content = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	marginLeft: theme.spacing(-12),
	'& > svg': {
		marginLeft: theme.spacing(5),
	},
	[theme.breakpoints.down('sm')]: {
		'& > svg': {
			marginLeft: theme.spacing(2.5),
			height: 17,
			width: 198,
		},
	},
}));
const Tag: React.FC<
	Omit<React.SVGProps<SVGSVGElement>, 'ref'> &
		Pick<IconProps, 'color' | 'htmlColor'>
> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		htmlColor || (color && palette[color]?.main) || '#FFFFFF';
	return (
		<svg
			width="407"
			height="35"
			viewBox="0 0 407 35"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M18.0084 21.7664H21.8741L21.2366 26.0845H17.326L16.5067 31.7968H12.0056L12.8699 26.0845H8.36878L7.54948 31.7968H3.04635L3.91061 26.0845H0L0.637466 21.7664H4.50108L5.63708 14.0313H1.77346L2.41093 9.75971H6.32154L7.14084 3.914H11.6419L10.8226 9.75971H15.2788L16.0981 3.914H20.6461L19.7819 9.75971H23.6475L23.0101 14.0313H19.1444L18.0084 21.7664ZM10.1872 14.0313L9.0512 21.7664H13.4624L14.6454 14.0313H10.1892H10.1872Z"
				fill={themeColor}
			/>
			<path
				d="M55.129 12.0976C55.129 9.98404 53.8112 7.87045 50.8548 7.87045C47.8983 7.87045 46.5355 9.98404 46.5355 12.0976V18.1697H60.9051V23.7466H46.5355V33.4598H40.5327V12.4128C40.5327 6.61159 44.5802 2.24902 50.8548 2.24902C57.1293 2.24902 61.1318 6.61159 61.1318 12.4128V14.3466H55.129V12.0976Z"
				fill={themeColor}
			/>
			<path
				d="M70.3157 24.1951C71.088 27.0281 73.3621 28.782 76.1816 28.782C78.1819 28.782 80.1372 27.9272 81.1383 26.0844H87.5498C86.1849 30.8067 82.0475 33.9993 76.2266 33.9993C69.6333 33.9993 64.2209 28.6021 64.2209 21.9017C64.2209 15.2012 69.6333 9.71521 76.2266 9.71521C82.8198 9.71521 88.8676 15.0214 88.0033 24.1971H70.3137L70.3157 24.1951ZM82.0475 19.3375C81.3202 16.6399 79.0461 14.9305 76.1816 14.9305C73.3171 14.9305 71.2699 16.6399 70.4056 19.3375H82.0475Z"
				fill={themeColor}
			/>
			<path
				d="M98.2354 33.4598H92.2327V0H98.2354V33.4598Z"
				fill={themeColor}
			/>
			<path
				d="M110.286 7.77952H104.284V2.24902H110.286V7.78154V7.77952ZM110.286 33.4578H104.284V10.2993H110.286V33.4598V33.4578Z"
				fill={themeColor}
			/>
			<path
				d="M127.02 33.9993C119.971 33.9993 114.924 28.6021 114.924 21.9017C114.924 15.2012 119.973 9.71521 127.02 9.71521C132.659 9.71521 136.933 12.8634 138.298 18.0807H131.887C131.067 16.5066 129.204 15.1568 127.022 15.1568C123.702 15.1568 121.019 18.214 121.019 21.9482C121.019 25.6823 123.702 28.6042 127.022 28.6042C129.251 28.6042 131.159 27.2544 131.934 25.6358H138.345C136.98 30.8976 132.706 34.0013 127.022 34.0013L127.02 33.9993Z"
				fill={themeColor}
			/>
			<path
				d="M148.755 7.77952H142.752V2.24902H148.755V7.78154V7.77952ZM148.755 33.4578H142.752V10.2993H148.755V33.4598V33.4578Z"
				fill={themeColor}
			/>
			<path
				d="M177.448 33.4598H171.719V30.9865C169.856 32.8758 167.4 33.9548 164.534 33.9548C158.441 33.9548 153.392 28.7376 153.392 21.9017C153.392 15.0659 158.441 9.80415 164.534 9.80415C167.261 9.80415 169.627 10.7943 171.446 12.5017V0H177.448V33.4598ZM165.443 15.2902C162.123 15.2902 159.44 18.1676 159.44 21.9462C159.44 25.7248 162.123 28.4668 165.443 28.4668C168.763 28.4668 171.446 25.5429 171.446 21.9462C171.446 18.3494 168.763 15.2902 165.443 15.2902Z"
				fill={themeColor}
			/>
			<path
				d="M205.869 33.4598H200.14V30.9865C198.321 32.8758 195.821 33.9548 192.954 33.9548C186.861 33.9548 181.813 28.7375 181.813 21.9017C181.813 15.0659 186.861 9.80414 192.954 9.80414C195.819 9.80414 198.319 10.8832 200.14 12.7725V10.2992H205.869V33.4598ZM193.863 15.2902C190.543 15.2902 187.86 18.1676 187.86 21.9462C187.86 25.7248 190.543 28.4668 193.863 28.4668C197.183 28.4668 199.866 25.5429 199.866 21.9462C199.866 18.3494 197.183 15.2902 193.863 15.2902Z"
				fill={themeColor}
			/>
			<path
				d="M233.56 33.4598H227.831V30.9865C225.967 32.8758 223.512 33.9548 220.645 33.9548C214.552 33.9548 209.504 28.7376 209.504 21.9017C209.504 15.0659 214.552 9.80415 220.645 9.80415C223.373 9.80415 225.739 10.7943 227.557 12.5017V0H233.56V33.4598ZM221.554 15.2902C218.234 15.2902 215.551 18.1676 215.551 21.9462C215.551 25.7248 218.234 28.4668 221.554 28.4668C224.874 28.4668 227.557 25.5429 227.557 21.9462C227.557 18.3494 224.874 15.2902 221.554 15.2902Z"
				fill={themeColor}
			/>
			<path
				d="M244.019 24.1951C244.791 27.0281 247.065 28.782 249.885 28.782C251.885 28.782 253.84 27.9272 254.841 26.0844H261.253C259.888 30.8067 255.751 33.9993 249.93 33.9993C243.336 33.9993 237.924 28.6021 237.924 21.9017C237.924 15.2012 243.336 9.71521 249.93 9.71521C256.523 9.71521 262.571 15.0214 261.706 24.1971H244.017L244.019 24.1951ZM255.751 19.3375C255.023 16.6399 252.749 14.9305 249.885 14.9305C247.02 14.9305 244.973 16.6399 244.109 19.3375H255.751Z"
				fill={themeColor}
			/>
			<path
				d="M272.394 33.4598H266.392V10.2992H272.394V33.4598ZM271.075 7.28645H266.663L270.893 0.945679H277.623L271.075 7.28645Z"
				fill={themeColor}
			/>
			<path
				d="M299.313 33.4598H293.31V19.9235C293.31 18.5737 292.902 15.3366 288.944 15.3366C286.262 15.3366 284.396 17.3148 284.396 19.9235V33.4598H278.393V10.2992H284.122V12.5481C285.622 10.7942 287.669 9.71521 290.17 9.71521C295.672 9.71521 299.311 13.807 299.311 19.9235V33.4598H299.313Z"
				fill={themeColor}
			/>
			<path
				d="M325.278 23.791C325.278 29.6368 321.275 33.9993 315.001 33.9993C308.726 33.9993 304.678 29.6368 304.678 23.791V4.40704H310.681V10.2992H325.051V15.8762H310.681V24.1063C310.681 26.2199 311.999 28.3779 315.001 28.3779C318.002 28.3779 319.275 26.2199 319.275 24.1063V21.9018H325.278V23.791Z"
				fill={themeColor}
			/>
			<path
				d="M336.235 7.77952H330.232V2.24902H336.235V7.78154V7.77952ZM336.235 33.4578H330.232V10.2993H336.235V33.4598V33.4578Z"
				fill={themeColor}
			/>
			<path
				d="M378.116 33.4598H372.113V19.9235C372.113 18.5737 371.657 15.3366 367.747 15.3366C365.064 15.3366 363.198 17.3148 363.198 19.9235V33.4598H357.151V19.9235C357.151 18.5737 356.742 15.3366 352.784 15.3366C350.147 15.3366 348.283 17.3148 348.283 19.9235V33.4598H342.236V10.2992H348.012V12.5481C349.466 10.7942 351.514 9.71521 354.059 9.71521C356.969 9.71521 359.425 10.9296 361.063 12.9988C362.745 10.9741 365.201 9.71521 368.202 9.71521C374.477 9.71521 378.116 13.8535 378.116 19.9235V33.4598Z"
				fill={themeColor}
			/>
			<path
				d="M406.446 33.4598H400.717V30.9865C398.899 32.8758 396.398 33.9548 393.531 33.9548C387.439 33.9548 382.39 28.7375 382.39 21.9017C382.39 15.0659 387.439 9.80414 393.531 9.80414C396.396 9.80414 398.897 10.8832 400.717 12.7725V10.2992H406.446V33.4598ZM394.441 15.2902C391.121 15.2902 388.438 18.1676 388.438 21.9462C388.438 25.7248 391.121 28.4668 394.441 28.4668C397.761 28.4668 400.444 25.5429 400.444 21.9462C400.444 18.3494 397.761 15.2902 394.441 15.2902Z"
				fill={themeColor}
			/>
		</svg>
	);
};
const HashTag: React.FC<Omit<BoxProps, 'ref'>> = props => {
	const { xs, sm, md, lg, xl } = useWidth();
	const isMobile = xs || sm;
	return (
		<Wrapper {...props}>
			<Content>
				<Tag />
				<Tag />
				<Tag />
				{isMobile ? null : (
					<>
						<Tag />
						<Tag />
						{lg || xl ? (
							<>
								<Tag />
								<Tag />
							</>
						) : null}
					</>
				)}
			</Content>
		</Wrapper>
	);
};
export default HashTag;
