import React from 'react';
import { animated, useInView } from '@react-spring/web';

import { styled } from '@ui/styles';
import { BoxProps, Box, Text, Row, Col, Image } from '@ui/components';
import { BulletDimond } from '@ui/icons';

import MobileContext from '@web/components/MobileContext';

import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import ContentBox from '@common-lib/components/Content/ContentBox';

const Root = styled(ContentWrapper)(({ theme }) => ({
	position: 'relative',
	background: '#FEE5E9',
}));

const Wrapper = styled(ContentBox)(({ theme }) => ({
	position: 'relative',
	paddingTop: theme.spacing(7),
	[theme.breakpoints.up('sm')]: {
		paddingTop: theme.spacing(15.75),
		paddingBottom: theme.spacing(25.75),
	},
	[theme.breakpoints.down('sm')]: {
		paddingTop: theme.spacing(7),
		paddingBottom: theme.spacing(11),
	},
}));

const Title = styled(animated.div)(({ theme }) => ({
	fontSize: '5.625rem',
	lineHeight: '5.625rem',
	fontFamily: theme.fontFamily.chalet,
	color: theme.palette.primary.contrastText,
	[theme.breakpoints.down('sm')]: {
		maxWidth: '100%',
		fontSize: '3rem',
		lineHeight: '3rem',
	},
	'@supports not (-webkit-text-stroke: inherit)': {
		color: '#9A4856',
	},
}));
const DescText = styled(Text)(({ theme }) => ({
	fontSize: '1rem',
	lineHeight: '1.5rem',
	paddingTop: theme.spacing(4),
	fontFamily: theme.fontFamily.mulish,
	[theme.breakpoints.up('sm')]: {
		maxWidth: 547,
	},
}));
const SubHead = styled(Text)(({ theme }) => ({
	fontSize: '1.5rem',
	lineHeight: '1.5rem',
	fontWeight: '700',
	fontFamily: theme.fontFamily.mulish,
	paddingTop: theme.spacing(4),
	[theme.breakpoints.up('sm')]: {
		paddingTop: theme.spacing(8),
	},
}));
const BG = styled(Box)(({ theme }) => ({
	marginTop: 52,
	minHeight: 986,
	background: "url('/images/ky-indication_bg.png')",
	backgroundSize: 'cover',
	[theme.breakpoints.down('lg')]: {
		backgroundSize: 'contain',
	},
	[theme.breakpoints.down('sm')]: {
		background: 'transparent',
	},
	backgroundRepeat: 'no-repeat',
}));
const BGTile = styled(Col)(({ theme }) => ({
	height: 93,
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	[theme.breakpoints.down('sm')]: {
		height: 60,
	},
	'&.tile-1': {
		background: 'linear-gradient(270deg, #FFE0E6 0%, #FCE9ED 100%)',
	},
	'&.tile-2': {
		background: 'linear-gradient(270deg, #E8B3B8 0%, #FBD6DA 100%)',
	},
	'&.tile-3': {
		background: 'linear-gradient(270deg, #C07B7B 0%, #DBA0A0 100%)',
	},
}));
const TileText = styled(Text)(({ theme }) => ({
	fontFamily: theme.fontFamily.chalet,
	fontWeight: 700,
	fontSize: '1.5rem',
	lineHeight: '1.5rem',
	[theme.breakpoints.down('sm')]: {
		fontSize: '1.125rem',
		lineHeight: '1.125rem',
	},
}));
const TopRow = styled(Row)(({ theme }) => ({
	paddingTop: theme.spacing(7),
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}));
const BottomRow = styled(Row)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		border: '2px solid #FFFFFF',
		borderRadius: 8,
		overflow: 'hidden',
	},
}));
const ContentRoot = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-end',
	[theme.breakpoints.up('sm')]: {
		minHeight: 665,
	},
}));
const HeadingWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	'& > svg': {
		width: 10,
		height: 7,
		top: 8,
		position: 'absolute',
		left: 0,
	},
	paddingLeft: 17,
	marginLeft: 10,
	marginBottom: theme.spacing(6),
	[theme.breakpoints.up('sm')]: {
		maxWidth: 358,
	},
	'&.mb-2': {
		marginBottom: theme.spacing(2),
	},
}));
const Heading = styled(Text)(({ theme }) => ({
	fontFamily: theme.fontFamily.chalet,
	fontWeight: 700,
	fontSize: '1.5rem',
	lineHeight: '1.5rem',
	color: theme.palette.primary.main,
	[theme.breakpoints.down('sm')]: {
		fontSize: '1.125rem',
		lineHeight: '1.125rem',
	},
}));
const SubHeading = styled(Text)(({ theme }) => ({
	fontFamily: theme.fontFamily.mulish,
	fontSize: '1rem',
	lineHeight: '1.5rem',
}));
const ImageWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	[theme.breakpoints.up('sm')]: {
		background: "url('/images/ky-indication_vector.png')",
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right 30% top -60px',
		backgroundSize: 'contain',
	},
	[theme.breakpoints.down('sm')]: {
		display: 'flex',
		flexDirection: 'column-reverse',
		alignItems: 'flex-end',
		paddingTop: theme.spacing(6.5),
		'&  img': {
			marginBottom: theme.spacing(6),
		},
	},
}));

const OulinedText: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [ref, prop] = useInView(() => ({
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0,
		},
		config: {
			duration: 1000,
		},
	}));
	return (
		<Title
			ref={ref}
			style={{
				WebkitTextFillColor: prop.opacity.to(
					v => `rgba(154, 72, 86, ${v})`,
				),
				WebkitTextStroke: prop.opacity
					.to([1, 0], [0, 1])
					.to(v => `1px rgba(154, 72, 86, ${v})`),
			}}
		>
			{children}
		</Title>
	);
};
const Content: React.FC<Omit<BoxProps, 'ref'>> = ({ ...rest }) => {
	return (
		<ContentRoot {...rest}>
			<HeadingWrapper>
				<BulletDimond />
				<Heading>alterações hormonais</Heading>
				<SubHeading>como amamentação, estresse e outros.</SubHeading>
			</HeadingWrapper>
			<HeadingWrapper>
				<BulletDimond />
				<Heading>uso de medicamentos</Heading>
				<SubHeading>
					e tratamentos médicos, dentre eles tratamento de câncer.
				</SubHeading>
			</HeadingWrapper>
			<HeadingWrapper>
				<BulletDimond />
				<Heading>alterações por agentes externos</Heading>
				<SubHeading>
					como por produtos de higiene e/ou sabonetes fortes.
				</SubHeading>
			</HeadingWrapper>
			<HeadingWrapper className="mb-2">
				<BulletDimond />
				<Heading>desconforto sexual</Heading>
				<SubHeading>
					decorrente de secura vaginal que afeta 17% das mulheres
					entre 18 e 50 anos.
				</SubHeading>
			</HeadingWrapper>
		</ContentRoot>
	);
};
const BGColor = styled('svg')(({ theme }) => ({
	position: 'absolute',
	zIndex: 0,
	top: 450,
	left: 40,
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}));
const Indications: React.FC<Omit<BoxProps, 'ref'>> = ({ px, ...rest }) => {
	const isMobile = React.useContext(MobileContext);
	return (
		<Root>
			<Wrapper {...rest} px={isMobile ? px : undefined}>
				<BGColor
					width="1126"
					height="1118"
					viewBox="0 0 1126 1118"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g opacity="0.43" filter="url(#filter0_f_427_2)">
						<rect
							x="266"
							y="257.017"
							width="800"
							height="800"
							rx="400"
							fill="url(#paint0_linear_427_2)"
						/>
					</g>
					<g opacity="0.43" filter="url(#filter1_f_427_2)">
						<rect
							x="60"
							y="60.0166"
							width="800"
							height="800"
							rx="400"
							fill="url(#paint1_linear_427_2)"
						/>
					</g>
					<defs>
						<filter
							id="filter0_f_427_2"
							x="206"
							y="197.017"
							width="920"
							height="920"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood
								floodOpacity="0"
								result="BackgroundImageFix"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="BackgroundImageFix"
								result="shape"
							/>
							<feGaussianBlur
								stdDeviation="30"
								result="effect1_foregroundBlur_427_2"
							/>
						</filter>
						<filter
							id="filter1_f_427_2"
							x="0"
							y="0.0166016"
							width="920"
							height="920"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood
								floodOpacity="0"
								result="BackgroundImageFix"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="BackgroundImageFix"
								result="shape"
							/>
							<feGaussianBlur
								stdDeviation="30"
								result="effect1_foregroundBlur_427_2"
							/>
						</filter>
						<linearGradient
							id="paint0_linear_427_2"
							x1="1066"
							y1="657.016"
							x2="266"
							y2="657.016"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#FFEBD9" />
							<stop offset="1" stopColor="#FFF5F6" />
						</linearGradient>
						<linearGradient
							id="paint1_linear_427_2"
							x1="860"
							y1="460.016"
							x2="60"
							y2="460.016"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#FFEBD9" />
							<stop offset="1" stopColor="#FFF5F6" />
						</linearGradient>
					</defs>
				</BGColor>

				<OulinedText>Indicações</OulinedText>
				<DescText>
					KY® Hidratante Intravaginal foi especialmente desenvolvido
					para mulheres que apresentam ressecamento vaginal na
					menopausa ou em outros momentos específicos da vida.
				</DescText>
				<SubHead color="primary">
					KY® Hidratante Intravaginal é Indicado para mulheres:
				</SubHead>
				<BG>
					<TopRow>
						<BGTile xs={12} md={4} className="tile-1"></BGTile>
						<BGTile xs={12} md={4} className="tile-2"></BGTile>
						<BGTile xs={12} md={4} className="tile-3"></BGTile>
					</TopRow>
					<BottomRow>
						<BGTile xs={12} md={4} className="tile-1">
							<TileText color="primary" ml={4.5}>
								pré-menopausa
							</TileText>
						</BGTile>
						<BGTile xs={12} md={4} className="tile-2">
							<TileText color="primary" ml={4.5}>
								perimenopausa
							</TileText>
						</BGTile>
						<BGTile xs={12} md={4} className="tile-3">
							<TileText color="primary.contrastText" ml={4.5}>
								pós-menopausa
							</TileText>
						</BGTile>
					</BottomRow>
					<ImageWrapper>
						<Content />
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="center"
							flex="1 1 auto"
						>
							<Image
								src="/images/ky-indication.png"
								height={isMobile ? 303 : 542}
								width={isMobile ? 365 : 600}
								alt="ky indication"
							/>
						</Box>
					</ImageWrapper>
				</BG>
			</Wrapper>
		</Root>
	);
};

export default Indications;
