import React from 'react';

import { styled } from '@ui/styles';

const BG = styled('svg')(({ theme }) => ({
	position: 'absolute',
	top: -720,
	// left: -427,
	'& *': {
		zIndex: 0,
	},
	zIndex: 0,
}));

const Background: React.FC = () => {
	return (
		<BG
			width="2886"
			height="2244"
			viewBox="0 0 2886 2244"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_f_1926_956)">
				<path
					d="M1417.13 1866.08C1348.37 1609.48 2068.75 1322.92 2028.12 1041.86C2314.33 965.168 2602.08 1111.01 2670.83 1367.61C2739.59 1624.21 2563.31 1894.39 2277.11 1971.08C1990.9 2047.77 1485.88 2122.68 1417.13 1866.08Z"
					fill="#AC6F7C"
					fillOpacity="0.25"
				/>
			</g>
			<g filter="url(#filter1_f_1926_956)">
				<path
					d="M1520.96 466.649C1641.62 703.314 996.675 1133.55 1094.92 1400C830.947 1534.58 519.139 1451.83 398.477 1215.17C277.816 978.503 393.992 677.546 657.964 542.962C921.936 408.377 1400.3 229.984 1520.96 466.649Z"
					fill="#D7A2AC"
					fillOpacity="0.6"
				/>
			</g>
			<g filter="url(#filter2_f_1926_956)">
				<path
					d="M2362 1025C2362 1251.99 2156.72 1436 1903.5 1436C1650.28 1436 1445 1251.99 1445 1025C1867.75 1194.24 1650.28 614 1903.5 614C2156.72 614 2362 798.011 2362 1025Z"
					fill="#FFEFB7"
					fillOpacity="0.18"
				/>
			</g>
			<defs>
				<filter
					id="filter0_f_1926_956"
					x="1212.5"
					y="821.423"
					width="1673.2"
					height="1422.4"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="100"
						result="effect1_foregroundBlur_1926_956"
					/>
				</filter>
				<filter
					id="filter1_f_1926_956"
					x="0.783203"
					y="0.994873"
					width="1885.28"
					height="1813.53"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="175"
						result="effect1_foregroundBlur_1926_956"
					/>
				</filter>
				<filter
					id="filter2_f_1926_956"
					x="1205"
					y="374"
					width="1397"
					height="1302"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="120"
						result="effect1_foregroundBlur_1926_956"
					/>
				</filter>
			</defs>
		</BG>
	);
};

export default Background;
